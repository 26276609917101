import React, { useContext, useState } from "react";
import inviteIcon from "../../images/inviteicon.png";
import profileIcon from "../../images/profileicon.png";
import styles from "./styles/People.module.css";
import Modal from "./Modal";
import axios from "axios";
import AuthContext from '../../context/AuthContext';

const People = ({ isTeacher, classroom, classroomId, students, teachers, setStudents, setTeachers }) => {
    const { userId } = useContext(AuthContext);
    const [isStudentModalOpen, setIsStudentModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [error, setError] = useState(null);
    const [role, setRole] = useState(null);

    const inviteUserToClassroom = async (userId, role) => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL_LOCAL}/api/notifications/sendInvite`, {
                invitedUserId: userId,
                classroomId: classroomId,
                name: classroom?.name,
                role: role,
            });
        } catch (error) {
            console.error(error);
        }
    };

    const searchUser = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/users/search?username=${searchTerm}`);
            setSearchResults(response.data);
        } catch (err) {
            setError(err.message);
        }
    };

    const removeUserFromClassroom = async (userId, role) => {
        try {
            await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/classrooms/removeUserFromClassroom/${classroomId}`, {
                userId,
                role,
            });

            if (role === 'student') {
                setStudents((prevStudents) => prevStudents.filter((student) => student._id !== userId));
            } else if (role === 'teacher') {
                setTeachers((prevTeachers) => prevTeachers.filter((teacher) => teacher._id !== userId));
            }
        } catch (error) {
            console.error(error);
        }
    };

    const changeUserRole = async (userId, currentRole) => {
        try {
            await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/classrooms/changeUserRole/${classroomId}`, {
                userId,
                role: currentRole,
            });

            if (currentRole === 'student') {
                const updatedStudent = students.find(student => student._id === userId);
                setStudents((prevStudents) => prevStudents.filter((student) => student._id !== userId));
                setTeachers((prevTeachers) => [...prevTeachers, updatedStudent]);
            } else if (currentRole === 'teacher') {
                const updatedTeacher = teachers.find(teacher => teacher._id === userId);
                setTeachers((prevTeachers) => prevTeachers.filter((teacher) => teacher._id !== userId));
                setStudents((prevStudents) => [...prevStudents, updatedTeacher]);
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className={styles.peopleContainer}>
            {isStudentModalOpen && (
                <Modal
                    isOpen={isStudentModalOpen}
                    onClose={() => setIsStudentModalOpen(false)}
                    onSubmit={inviteUserToClassroom}
                    operation='invite'
                    inviteUserToClassroom={inviteUserToClassroom}
                    role={role}
                    classroomId={classroomId}
                    searchUser={searchUser}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    searchResults={searchResults}
                    setSearchResults={setSearchResults}
                >
                    {error && <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>}
                </Modal>
            )}
            <div className={styles.categoryContainer}>
                <div className={styles.header}>
                    <h2>Teachers</h2>
                    {isTeacher && (
                        <div onClick={() => { setRole('teacher'); setIsStudentModalOpen(true) }}>
                            <img src={inviteIcon} alt="Invite Icon" className={styles.inviteIcon} />
                        </div>
                    )}
                </div>
                <div className={styles.list}>
                    {teachers?.map((teacher) => (
                        <div key={teacher._id} className={styles.item}>
                            <div className={styles.profileInfo}>
                                <img src={teacher.profilePicture || profileIcon} alt="Teacher Icon" className={styles.profileIcon} />
                                {teacher.username}
                            </div>
                            {isTeacher && teacher._id !== userId && (
                                <div className={styles.actions}>
                                    <button onClick={() => changeUserRole(teacher._id, 'teacher')} className={styles.changeRoleButton}>
                                        Demote
                                    </button>
                                    <button onClick={() => removeUserFromClassroom(teacher._id, 'teacher')} className={styles.removeButton}>
                                        Remove
                                    </button>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
    
            <div className={styles.categoryContainer}>
                <div className={styles.header}>
                    <h2>Students</h2>
                    {isTeacher && (
                        <div onClick={() => { setRole('student'); setIsStudentModalOpen(true) }}>
                            <img src={inviteIcon} alt="Invite Icon" className={styles.inviteIcon} />
                        </div>
                    )}
                </div>
                <div className={styles.list}>
                    {students?.map((student) => (
                        <div key={student._id} className={styles.item}>
                            <div className={styles.profileInfo}>
                                <img src={student.profilePicture || profileIcon} alt="Student Icon" className={styles.profileIcon} />
                                {student.username}
                            </div>
                            {isTeacher && student._id !== userId && (
                                <div className={styles.actions}>
                                    <button onClick={() => changeUserRole(student._id, 'student')} className={styles.changeRoleButton}>
                                        Promote
                                    </button>
                                    <button onClick={() => removeUserFromClassroom(student._id, 'student')} className={styles.removeButton}>
                                        Remove
                                    </button>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default People;
