import React from 'react';
import axios from 'axios';
import styles from './styles/FeedbackModal.module.css';

const FeedbackModal = ({ userId, closeFeedbackModal, feedbackText, setFeedbackText, scenario, situation }) => {
    
    const handleSendFeedback = () => {
        let feedback = {
            text: feedbackText,
            recipient: scenario.creator,
            situationOrigin: situation._id,
            scenarioOrigin: scenario._id,
        };

        if (userId) {
            feedback.sender = userId;
        }

        axios.post(`${process.env.REACT_APP_API_URL_LOCAL}/api/feedback`, feedback)
            .then(() => {
                axios.post(`${process.env.REACT_APP_API_URL_LOCAL}/api/notifications/sendFeedbackNotification/${scenario.creator}`, { feedbackText: feedbackText });
            })
            .then(() => {
                closeFeedbackModal();
            })
            .catch(error => console.error('Error sending feedback:', error));
    };

    return (
        <div className={styles.modalBackdrop}>
            <div className={styles.modalContent}>
                <h2>Send Feedback</h2>
                <textarea
                    className={styles.textarea}
                    value={feedbackText}
                    onChange={(e) => setFeedbackText(e.target.value)}
                    placeholder="Enter your feedback here..."
                />
                <div className={styles.buttonContainer}>
                    <button onClick={closeFeedbackModal}>Close</button>
                    <button onClick={handleSendFeedback}>Send</button>
                </div>
            </div>
        </div>
    );
};

export default FeedbackModal;
