import React, { useEffect, useState } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKFinderUploadAdapter, MediaEmbed } from '@ckeditor/ckeditor5-build-classic';
import styles from './styles/ClassroomAbout.module.css';
import axios from 'axios';

const ClassroomAbout = ({ isOwner, about, setAbout, classroomId, refreshData, setRefreshData }) => {


    const [isEditing, setIsEditing] = useState(false);
    const [editor, setEditor] = useState(null);
    const [aboutText, setAboutText] = useState('');

    useEffect(() => {
        if (editor) {
            editor.setData(about);
        }
        setAboutText(about);
    }, [editor, isOwner, about]);

    const transformIframes = (html) => {
        if (typeof html !== 'string') {
            return '';
        }
        return html.replace(/<iframe url="https:\/\/www.youtube.com\/watch\?v=([^"]+)"><\/iframe>/g, (videoId) => {
            return `<iframe width="560" height="315" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allowfullscreen></iframe>`;
        });
    };

    const handleEditAbout = async () => {
        try {
            const response = await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/classrooms/${classroomId}`, {
                about: aboutText
            });

            setAbout(response.data.about);
            setIsEditing(false); 
        } catch (error) {
            console.error('Error updating classroom about section:', error);
        }
    };





    return (
        <div className={styles.aboutContainer}>
            {(!isEditing && about !== '') ? (
                <>
                    {isOwner && (
                        <div className={styles.editAbout}>
                            <button onClick={() => setIsEditing(true)}>Edit</button>
                        </div>
                    )}
                    <div>
                        <div className={`${styles.htmlContent}`} dangerouslySetInnerHTML={{ __html: transformIframes(about) }} />
                    </div>
                </>
            ) : (
                <>
                    {isOwner && (
                        <div>
                            {about === '' && <div>Add an About section</div>}

                            <div style={{ wordBreak: 'break-all', overflowWrap: 'break-word', wordWrap: 'break-word' }}>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={about}
                                    plugins={[CKFinderUploadAdapter, MediaEmbed]}
                                    config={{
                                        ckfinder: {
                                            uploadUrl: `${process.env.REACT_APP_API_URL_LOCAL}/api/images/upload`
                                        },
                                        mediaEmbed: {
                                            elementName: 'iframe',
                                        }
                                    }}
                                    onReady={(editor) => {
                                        setEditor(editor);
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setAboutText(data);
                                    }}
                                />
                            </div>
                            <div className={styles.postAnnouncement}>
                                <button onClick={handleEditAbout}>Save</button>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );


}

export default ClassroomAbout;
