import React, { useState, useEffect } from 'react';
import styles from './styles/MyClassroomsSidebar.module.css';
import { FaHome } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';

const ClassroomSidebar = ({ classrooms, joinedClassrooms, myClassroomsTabOpen, setMyClassroomsTabOpen }) => {
    const [selectedClassroomId, setSelectedClassroomId] = useState(null);
    const classroomIdObj = useParams();
    const rawClassroomId = classroomIdObj["*"];
    const actualClassroomId = rawClassroomId.split('/')[0]; 
    useEffect(() => {
        if (actualClassroomId) {
            setSelectedClassroomId(actualClassroomId);
        }
    }, [actualClassroomId]);

    return (
        <div className={styles.sidebarContainer}>
            <div className={styles.sidebarOptions}>
                <Link to={`/my-classrooms`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <div
                        className={`${styles.sidebarClassroomItem} ${myClassroomsTabOpen ? styles.sidebarClassroomItemSelected : ''}`}
                        onClick={() => {
                            setSelectedClassroomId(null);
                            setMyClassroomsTabOpen(true);
                        }}
                    >
                        <FaHome style={{ marginRight: '5px', paddingBottom: '4px' }} />Home
                    </div>
                </Link>
            </div>
            <div className={styles.sidebarClassrooms}>
                {classrooms.slice().reverse().map((classroom) => (
                    <Link to={`/my-classrooms/${classroom._id}/a`} key={classroom._id} style={{ textDecoration: 'none', color: 'inherit' }}>
                        <div
                            className={`${styles.sidebarClassroomItem} ${selectedClassroomId === classroom._id ? styles.sidebarClassroomItemSelected : ''}`}
                            key={classroom._id}
                            onClick={() => {
                                setSelectedClassroomId(classroom._id);
                                setMyClassroomsTabOpen(false);
                            }}
                        >
                            {classroom.name}
                        </div>
                    </Link>
                ))}
            </div>
            <div className={styles.sidebarClassrooms}>
                {joinedClassrooms.slice().reverse().map((classroom) => (
                    <Link to={`/my-classrooms/${classroom._id}/a`} key={classroom._id} style={{ textDecoration: 'none', color: 'inherit' }}>
                        <div
                            className={`${styles.sidebarClassroomItem} ${selectedClassroomId === classroom._id ? styles.sidebarClassroomItemSelected : ''}`}
                            key={classroom._id}
                            onClick={() => {
                                setSelectedClassroomId(classroom._id);
                                setMyClassroomsTabOpen(false);
                            }}
                        >
                            {classroom.name}
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default ClassroomSidebar;
