import React from "react";
import { useLocation } from "react-router-dom";
import Navbar from "./navbar/Navbar";
import styles from './MainLayout.module.css';

const MainLayout = ({ children }) => {
  const location = useLocation();
  const displayNavbar = !["/login", "/register", "/authenticate", "/forgot-password", "/reset-password"].includes(location.pathname);

  return (
    <div className={styles.mainContainer}>
      {displayNavbar && <Navbar />}
      <div className={styles.bodyContainer}>
        {children}
      </div>
    </div>
  );
};

export default MainLayout;
