import React, { createContext, useEffect, useState } from 'react';
import axios from 'axios';

export const ClassroomContext = createContext({ classroom: null });

export const ClassroomContextProvider = ({ children }) => {
  const [classroom, setClassroom] = useState(null);
  const [classroomId, setClassroomId] = useState(null);

  const fetchClassroom = async (id) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/classrooms/populated/${id}`);
      setClassroom(response.data);
    } catch (error) {
      console.error('Could not fetch classroom', error);
    }
  };

  // Fetch the classroom when the component mounts or classroomId changes
  useEffect(() => {
    if (classroomId) {
      fetchClassroom(classroomId);
    }
  }, [classroomId]);

  return (
    <ClassroomContext.Provider value={{ classroom, setClassroom, setClassroomId }}>
      {children}
    </ClassroomContext.Provider>
  );
};
