import React, { useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import styles from "./styles/Browse.module.css";
import ScenarioCard from "../mainlayout/body/ScenarioCard";
import axios from 'axios';

const Browse = () => {
    const { tag } = useParams();
    const [scenarios, setScenarios] = useState([]);

    useEffect(() => {
        const fetchScenarios = async () => {
          try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/search/tags?tag=${tag}`);
            setScenarios(response.data);
          } catch (error) {
            console.error(`Error: ${error}`);
          }
        }
        fetchScenarios();
      }, [tag]);

      return (
        <div className={styles.container}>
            <h3>Scenarios in {tag}</h3>
            <div className={styles.browseResults}>
                {scenarios.map(scenario => (
                <div key={scenario._id}>
                    <ScenarioCard key={scenario._id} scenario={scenario} />
                </div>
                ))}
            </div>
        </div>
      )

}

export default Browse;