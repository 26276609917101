import React, { useState, useEffect, useContext } from 'react';
import { AiOutlineCopy } from 'react-icons/ai';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useNavigate } from 'react-router-dom';
import './styles/ScenarioCard.css';
import styles from '../../classrooms/styles/Folders.module.css';
import { Tooltip, OverlayTrigger, Modal } from 'react-bootstrap';
import blankImage from '../../../images/blankimage.png';
import shareImage from '../../../images/share.png';
import viewsImage from '../../../images/eye.png';
import favorite from '../../../images/Favorite.png';
import favoriteOutline from '../../../images/FavoriteOutline.png';
import { calculateTimeSince } from '../../../utils/utils';
import axios from 'axios';
import AuthContext from '../../../context/AuthContext';
import ShareModal from './ShareModal';

const ScenarioCard = ({ scenario, isAuthor, onDeleteScenario, onCopyScenario, searchQuery }) => {
  const navigate = useNavigate();
  const scenarioId = scenario._id;
  const { userId } = useContext(AuthContext);

  const [likesCount, setLikesCount] = useState();
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');
  const [openedDropdown, setOpenedDropdown] = useState(false);
  const [isHovered, setHovered] = useState(false);
  const [hasBeenLiked, setHasBeenLiked] = useState(false);

  useEffect(() => {
    setLikesCount(scenario.likedBy.length);
    if (userId) {
      setHasBeenLiked(scenario.likedBy.includes(userId));
    }
  }, [scenario.likedBy, userId]);

  const handleClickLikeScenario = async () => {
    if (scenarioId && userId) {
      try {
        await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/likeScenario/${scenarioId}`);

        if (hasBeenLiked) {
          setLikesCount(likesCount - 1);
        } else {
          setLikesCount(likesCount + 1);
        }
        setHasBeenLiked(!hasBeenLiked);
      } catch (error) {
        console.error('Error liking scenario: ', error);
      }
    }
  }

  const highlightText = (text, query) => {
    if (!query) {
      return text;
    }
    const parts = text.split(new RegExp(`(${query})`, 'gi'));
    return parts.map((part, index) =>
      part.toLowerCase() === query.toLowerCase() ? <span key={index} className="highlight">{part}</span> : part
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (openedDropdown && !event.target.closest(`.${styles.optionsDropdown}`) && !event.target.closest(`.${styles.optionsButton}`)) {
        setOpenedDropdown(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [openedDropdown, styles.optionsDropdown, styles.optionsButton]);

  const handleShare = () => setShowShareModal(true);
  const handleCloseShare = () => setShowShareModal(false);

  const onCopy = () => {
    setCopySuccess('Copied!');
    setTimeout(() => setCopySuccess(''), 2000);
  };

  const navigateToUser = (username) => {
    if (username) {
      navigate(`/user/${username}`);
    }
  };

  if (!isImageLoaded) {
    return (
      <img
        src={scenario.image ? scenario.image : blankImage}
        className="scenariocard-img"
        style={{ display: 'none' }}
        onLoad={() => setIsImageLoaded(true)}
        alt=''
      />
    );
  }

  const shareUrl = `${window.location.origin}/view/${scenario._id}`;

  const handleTagClick = (tag) => {
    navigate(`/browse/${tag}`);
  };

  return (
    <>
      <div className={`scenariocard ${scenario.isPublished ? '' : 'unpublished'}`}>
        {isAuthor && (<button
          className='sc-options-button'
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setOpenedDropdown(scenario._id === openedDropdown ? null : scenario._id);
          }}
        />)}
        {openedDropdown === scenario._id && (
          <div className='sc-options-dropdown'>
            {isAuthor && (
              <div
                className='sc-options-dropdown-item'
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  navigate(`/create/${scenario._id}`);
                }}
              >
                Edit
              </div>
            )}
            {isAuthor && (
              <>
                <div
                  className='sc-options-dropdown-item'
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onCopyScenario(e, scenario._id);
                    setOpenedDropdown(false);
                  }}
                >
                  Copy
                </div>
                <div
                  className='sc-options-dropdown-item'
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onDeleteScenario(e, scenario._id);
                    setOpenedDropdown(false);
                  }}
                >
                  Delete
                </div>
              </>
            )}
          </div>
        )}
        {!scenario.isPublished && (
          <div className='tooltip-container'>
            <img src={scenario.image ? scenario.image : blankImage} alt='Scenario' className="scenariocard-img" />
            <span className='exclamation-icon'>
              <div className='tooltip-text'>This Scenario is not published</div>!
            </span>
          </div>
        )}
        {scenario.isPublished && (
          <img
            src={scenario.image ? scenario.image : blankImage}
            alt='Scenario'
            className="scenariocard-img"
            onClick={() => navigate(`/view/${scenario._id}`)}
            style={{ cursor: 'pointer' }}
          />
        )}
        <div className='sc-body'>
          <div className='sc-title-container'>
            <div
              className={`sc-title ${isHovered ? 'hovered' : ''}`}
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
              title={scenario.title}
              onClick={() => {
                if (scenario.isPublished) {
                  navigate(`/view/${scenario._id}`);
                }
              }}
            >
              {highlightText(scenario.title || 'Untitled', searchQuery)}
            </div>
            <img
              src={shareImage}
              className="sc-share"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleShare();
              }}
            />
          </div>
          <div className="sc-user-date-container">
            <div className='sc-username'>
              <a
                href={`/user/${scenario.creator?.username}`}
                onClick={(event) => {
                  event.preventDefault();
                  navigateToUser(scenario.creator?.username);
                }}
                className="sc-username-text-style"
              >
                {scenario.creator?.username}
              </a>
            </div>
            <div className='sc-date'>
              {calculateTimeSince(scenario.createdAt)} ago
            </div>
          </div>
          <div className="sc-views-likes">
            <div className='sc-like'>
              {userId ? (
                hasBeenLiked ?
                  <img
                    src={favorite}
                    className="sc-likes-image"
                    onClick={handleClickLikeScenario}
                  /> :
                  <img
                    src={favoriteOutline}
                    className="sc-likes-image"
                    onClick={handleClickLikeScenario}
                  />
              ) : <img src={favorite}
                className="sc-likes-image" />}
              <div className="sc-like-count">
                {likesCount}
              </div>
            </div>
            <div className='sc-views'>
              <img src={viewsImage} className="sc-views-image" />
              {scenario.views}
            </div>
          </div>
        </div>

        {scenario.tags && (
          <div className="sc-tag-span">
            {scenario.tags.map((tag, index) => (
              <div key={index} className="sc-tag-div" onClick={() => handleTagClick(tag)}>
                <div className='sc-tag-text'>
                  {highlightText(tag, searchQuery)}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {showShareModal && (
        <ShareModal
          handleCloseShare={handleCloseShare}
          onCopy={onCopy}
          copySuccess={copySuccess}
          shareUrl={shareUrl}
        />
      )}
    </>
  );
};

export default ScenarioCard;
