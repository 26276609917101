import React, { useEffect } from 'react';
import styles from './styles/FrontPage.module.css';
import { Helmet } from 'react-helmet';
import ScenarioCarousels from './ScenarioCarousels';

const FrontPage = () => {


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div className={styles.frontPage}>
      <Helmet>
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7008167333969476" crossorigin="anonymous"></script>
      </Helmet>
      <ScenarioCarousels />
    </div>
  );
};

export default FrontPage;
