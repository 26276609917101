import React, {useState, useEffect} from 'react';
import { useParams, useNavigate, Routes, Route, Link } from 'react-router-dom';
import Modal from './Modal';
import axios from 'axios';
import styles from './styles/Folders.module.css';
import modalStyles from './styles/Modal.module.css';
import folderIcon from '../../images/foldericon.png';
import addFolderIcon from '../../images/addfoldericon.png';
import settingsIcon from '../../images/settingsicon.png';
import profileIcon from '../../images/profileicon.png';

const Folders = ({isTeacher, classroomId, folders, setFolders, students, userId, teachers, navigate}) => {
  // State and functions for folders here...
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [editingFolderName, setEditingFolderName] = useState('');
  const [error, setError] = useState(null);

  const [editingFolder, setEditingFolder] = useState(null);
  const [openedDropdown, setOpenedDropdown] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [selectedStudents, setSelectedStudents] = useState([]);
  const [toggleSelected, setToggleSelected] = useState(false);


  useEffect(() => {
    const handleClickOutside = (event) => {
        // Check if the click was outside of the dropdown and the optionsButton
        if (openedDropdown && !event.target.closest(`.${styles.optionsDropdown}`) && !event.target.closest(`.${styles.optionsButton}`)) {
            setOpenedDropdown(false);
        }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
        document.removeEventListener("click", handleClickOutside);
    };
  }, [openedDropdown, styles.optionsDropdown, styles.optionsButton]);

  useEffect(() => {
    // Initialize the input field when the modal is opened and editingFolder is set
    if (editingFolder) {
      setEditingFolderName(editingFolder.name);
    }
  }, [editingFolder, isEditModalOpen]);

  useEffect(() => {
    const targetFolder = folders.find(folder => folder?._id === editingFolder?._id);
    if (targetFolder) {
      const allowedStudentIDs = targetFolder.allowedStudents;
      
      // Map allowedStudentIDs to student objects
      const allowedStudentObjects = students.filter(student => 
        allowedStudentIDs.includes(student?._id)
      );
  
      setSelectedStudents(allowedStudentObjects);
    }
  }, [editingFolder, students, folders]);
  
  
  
  
  
  

  const createFolder = () => {

    if (newFolderName.trim() === "") {
        setError("This field cannot be empty");
        return;
    }

      
    const data = {
      classroomId,
      name: newFolderName,
      students: students
    };
  
    axios.post(`${process.env.REACT_APP_API_URL_LOCAL}/api/folders`, data)
      .then(response => {
        const newFolder = response.data;
  
        if (newFolder._id) {
          // Update local folders state
          setFolders(prevFolders => [...prevFolders, newFolder]);
  
          // Navigate to the newly created folder
        }
        
        setIsFolderModalOpen(false); // Close the modal
        setNewFolderName('');
      })
      .catch(error => {
        console.log("Error creating folder:", error);
      });
  };

  

  const handleNameChange = (e) => {
    setNewFolderName(e.target.value);
  };

  const handleEditNameChange = (e) => {
    setEditingFolderName(e.target.value);
  };

  
  
  
  const editFolder = async () => {

    if (editingFolderName.trim() === "") {
        setError("This field cannot be empty");
        return;
    }
  
    const updatedAllowedStudents = selectedStudents.map(user => user?._id);
  
    try {
        const response = await axios.patch(
        `${process.env.REACT_APP_API_URL_LOCAL}/api/folders/${editingFolder._id}`,
        { 
            name: editingFolderName,
            allowedStudents: updatedAllowedStudents 
        }
    );
    
        // Find and update the classroom
        const updatedFolders = folders.map((folder) => {
            if (folder._id === editingFolder._id) {
              return { ...folder, name: editingFolderName, allowedStudents: updatedAllowedStudents };
            }
            return folder;
        });
        
  
      setFolders(updatedFolders); // Update the state
  
      setIsEditModalOpen(false); // Close modal after successful edit
      setNewFolderName("");
      setToggleSelected()
    } catch (error) {
      console.log("Error updating classroom:", error);
    }
  };

  const toggleStudentSelection = (student) => {
    if (selectedStudents.some(s => s?._id === student?._id)) {
      const newSelectedStudents = selectedStudents.filter(s => s?._id !== student?._id);
      setSelectedStudents(newSelectedStudents);
    } else {
      setSelectedStudents([...selectedStudents, student]);
    }
  };

  const deleteFolder = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL_LOCAL}/api/folders/${editingFolder._id}`
      );
  
      // Update the frontend state to remove the folder
      const updatedFolders = folders.filter(
        (folder) => folder._id !== editingFolder._id
      );
  
      setFolders(updatedFolders); // Update the state
      setIsEditModalOpen(false); // Close modal after successful delete
    } catch (error) {
      console.log("Error deleting folder:", error);
    }
  };
  

  const handleFolderClick = (folder, userId, classroomId) => {
    // Check if the userId exists in the teacherIds array
    if (isTeacher) {
      navigate(`/my-classrooms/${classroomId}/f/${folder._id}`);
      return;
    }
  
    // Check if the userId exists in the folder's allowedStudents array
    if (folder.allowedStudents.includes(userId)) {
      navigate(`/my-classrooms/${classroomId}/f/${folder._id}`);
      return;
    }
  
    // The user is neither a teacher nor an allowed student.
    alert("You do not have permission to access this folder.");
  };
  
  
  

  return (
    <div className={styles.foldersContainer}>
      <Modal isOpen={isFolderModalOpen} onClose={() => setIsFolderModalOpen(false)} onSubmit={createFolder} operation='create' name={newFolderName} type='folder'>
          <label htmlFor="folderName">Create your Folder</label>
          <input
              type="text"
              id="folderName"
              value={newFolderName}
              onChange={handleNameChange}
              className='modal-input'
          />
          {error && <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>}
      </Modal>
      {isEditModalOpen && (
        <Modal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)} onSubmit={editFolder} operation='edit-folder' name={editingFolderName} type='folder'> 
            <label htmlFor="folderName">Edit your Folder</label>
            <input type="text" id="folderName" className='modal-input' value={editingFolderName} onChange={handleEditNameChange} />
            <div className={styles.listHeader}>
                The following students have access to this folder:
            </div>

            <ul className={modalStyles.list} style={{marginTop:'20px', borderTop:'0px'}}>
                {students.map(student => (
                <li 
                    key={student?._id} 
                    onClick={() => toggleStudentSelection(student)}
                    style={selectedStudents.some(s => s?._id === student?._id) ? { backgroundColor: 'lightblue' } : {}}
                    className={modalStyles.item}
                >
                    <img src={profileIcon} alt="User Icon" className={modalStyles.profileIcon} /> {student?.username}
                </li>
                ))}
            </ul>

            {error && <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>}
            <button onClick={deleteFolder} className={styles.deleteButton}>Delete Folder</button>
        </Modal>
        )}

      <div className={styles.foldersHeader}>
          <h2 className={styles.title}>Folders</h2>
          {isTeacher && (
            <div className={styles.buttonContainer}>
                <img src={addFolderIcon} className={styles.addFolderIcon} onClick={() => setIsFolderModalOpen(true)}/>
            </div>
          )}
      </div>
  
      <div className={styles.foldersBody}>
        {folders.filter(folder => !folder.parentFolder).map((folder, index) => (
            <div key={index} className={styles.folderItem}>
            <div 
                onClick={() => handleFolderClick(folder, userId, classroomId)}
                style={{ textDecoration: 'none', color: 'inherit', cursor:'pointer' }}
            >
                <img src={folderIcon} className={styles.folderItemImage} alt="folder icon" />
                <div className={styles.folderName}>{folder?.name}</div>
            </div>
            {isTeacher && (
                <img 
                    src={settingsIcon} 
                    className={styles.optionsButton} 
                    onClick={(e) => {
                        e.stopPropagation();
                        setOpenedDropdown(folder._id === openedDropdown ? null : folder._id);
                        setIsEditModalOpen(true);
                        setEditingFolder(folder);
                    }} 
                />
            )}
            </div>
        ))}
     </div>

    </div>
  );
};

export default Folders;
