import React, { useContext, useRef, useEffect } from 'react';
import styles from './styles/NavbarMenu.module.css';
import AuthContext from '../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import profileIcon from '../../../images/profileicon.png';
import classroomIcon from '../../../images/classroomicon.png';
import logoutIcon from '../../../images/logouticon.png';

const NavbarMenu = ({ setShowMenu, user }) => {
    const { getLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate();
    const menuRef = useRef(null);

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setShowMenu(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleClickProfile = async () => {
        try {
            if (user) {
                navigate(`/user/${user.username}`, { state: { fromNavbar: true } });
                setShowMenu(false);
            }
        } catch (error) {
            console.error(`Error retrieving user information: ${error}`);
        }
    };

    const handleClickClassrooms = () => {
        navigate('/my-classrooms');
        setShowMenu(false);
    };

    const handleClickLogout = async () => {
        try {
            await axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/users/logout`, { withCredentials: true });
            await getLoggedIn();
            navigate("/logging-out");
            setShowMenu(false);
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    return (
        <div ref={menuRef} className={styles.dropdown}>
            <div className={styles.menuItem} onClick={handleClickProfile}>
                <img
                    src={user && user.profilePicture ? user.profilePicture : profileIcon}
                    alt="Profile"
                    className={styles.profileIcon}
                />
                Account
            </div>
            <div className={styles.menuItem} onClick={handleClickClassrooms}>
                <img
                    src={classroomIcon}
                    alt="Classrooms"
                    className={styles.profileIcon}
                />
                Classrooms
            </div>
            <div className={styles.menuItem} onClick={handleClickLogout}>
                <img
                    src={logoutIcon}
                    alt="Logout"
                    className={styles.profileIcon}
                />
                Logout
            </div>
        </div>
    );
};

export default NavbarMenu;
