import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useParams, useNavigate, Routes, Route } from 'react-router-dom';
import styles from './styles/Classroom.module.css';
import AuthContext from '../../context/AuthContext';
import { ClassroomContextProvider } from '../../context/ClassroomContext';
import ClassroomNavbar from './ClassroomNavbar';
import ClassroomAnnouncements from './ClassroomAnnouncements';
import Folders from './Folders';
import Folder from './Folder';
import People from './People';
import ClassroomSettings from './ClassroomSettings';
import ClassroomAbout from './ClassroomAbout';
import ClassroomDescription from './ClassroomDescription';
import ClassroomLogs from './ClassroomLogs';

const Classroom = () => {
  const onDescriptionUpdate = (updatedDescription) => {
    setDescription(updatedDescription);
  };
  const { classroomId } = useParams();
  const [classroom, setClassroom] = useState(null);
  const [folders, setFolders] = useState([]);
  const { userId } = useContext(AuthContext);
  const [students, setStudents] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [announcements, setAnnouncements] = useState([]);
  const [about, setAbout] = useState('');
  const [description, setDescription] = useState('');
  const [isTeacher, setIsTeacher] = useState(false);
  const [owner, setOwner] = useState(null);
  const [isOwner, setIsOwner] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [scenarios, setScenarios] = useState([]);


  const navigate = useNavigate();


  useEffect(() => {
    if (classroomId) {
      axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/classrooms/populated/${classroomId}`)
        .then(response => {
          setDescription(response.data.description)
          setClassroom(response.data);
          setFolders(response.data.folders);
          setStudents(response.data.students);
          setTeachers(response.data.teachers);
          setAnnouncements(response.data.announcements);
          setAbout(response.data.about);
          setOwner(response.data.owner);
          setScenarios(response.data.scenarios);
          
        })
        .catch(error => {
          console.error(`Error: ${error}`);
        });
    }
  }, [classroomId, userId, refreshData]);


  useEffect(() => {
    if (teachers && teachers.length > 0) {
      const foundTeacher = teachers.find(teacher => teacher._id === userId);
      setIsTeacher(!!foundTeacher);
    } else {
      setIsTeacher(false);
    }
  }, [teachers, userId]);

  useEffect(() => {
    if (owner && userId) {
      if (userId === owner._id) {
        setIsOwner(true);
      } else {
        setIsOwner(false);
      }
    }
  }, [userId, owner])

  return (
    <div className={styles.classroomContainer} style={{ display: 'flex', flexDirection: 'column' }}>
      {classroom ? (
        <ClassroomContextProvider>
          <div className={styles.classroomNavbar}>
            <ClassroomNavbar isOwner={isOwner} isTeacher={isTeacher} classroomId={classroomId} classroomName={classroom?.name} />
          </div>
          <div className={styles.classroomBody}>
            <Routes>
              <Route path="/a" element={<ClassroomAnnouncements isTeacher={isTeacher} userId={userId}
                classroomId={classroomId} announcements={announcements} setAnnouncements={setAnnouncements} students={students}
                classroomName={classroom?.name} refreshData={refreshData} setRefreshData={setRefreshData} />} />
              <Route path="/f" element={<Folders isTeacher={isTeacher} classroomId={classroomId} folders={folders} setFolders={setFolders} students={students} userId={userId} teachers={teachers} navigate={navigate} />} />
              <Route path="/f/:folderId" element={<Folder isTeacher={isTeacher} students={students} userId={userId} teachers={teachers} />} />
              <Route path="/p" element={<People isTeacher={isTeacher} classroom={classroom} classroomId={classroomId} students={students} teachers={teachers} setStudents={setStudents} setTeachers={setTeachers} />} />
              <Route path="/i" element={<ClassroomAbout isOwner={isOwner} about={about} setAbout={setAbout} classroom={classroom} classroomId={classroomId} refreshData={refreshData} setRefreshData={setRefreshData} />} />
              <Route path="/d" element={<ClassroomDescription onDescriptionUpdate={onDescriptionUpdate} isOwner={isOwner} classroom={classroom} classroomId={classroomId} refreshData={refreshData} setRefreshData={setRefreshData} description={description} />} />
              <Route path="/l" element={<ClassroomLogs students={students} isTeacher={isTeacher} scenarios={scenarios} />} />
              <Route path="/settings" element={<ClassroomSettings isOwner={isOwner} classroomId={classroomId} students={students} teachers={teachers} navigate={navigate} />} />
            </Routes>
          </div>
        </ClassroomContextProvider>
      ) : (
        <div>Loading...</div>
      )


      }
    </div>
  );



};

export default Classroom;
