import React, { useState, useEffect, useContext, useRef, useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import './styles/ScenarioTitlePage.css';
import AuthContext from '../../../context/AuthContext';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKFinderUploadAdapter, MediaEmbed } from '@ckeditor/ckeditor5-build-classic';

const ScenarioTitlePage = () => {
  const navigate = useNavigate();
  const { scenarioId } = useParams();
  const { userId } = useContext(AuthContext);

  const handleNavigation = useCallback((loggedStatus) => {
    sessionStorage.setItem('isLogged', loggedStatus);
    navigate(`/create/${scenarioId}/canvas?isLogged=${loggedStatus}`);
  }, [navigate, scenarioId]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: useCallback((acceptedFiles) => {
      if (acceptedFiles.length) {
        setScenario((prev) => ({ ...prev, image: acceptedFiles[0] }));
      }
    }, []),
  });

  const [scenario, setScenario] = useState({ title: '', description: '', image: null });
  const [tags, setTags] = useState([]);
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState('');
  const [isPrivate, setIsPrivate] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [password, setPassword] = useState('');
  const [isLogged, setIsLogged] = useState(false);
  const [initialData, setInitialData] = useState('');
  const editorRef = useRef(null);
  const [originalIsLogged, setOriginalIsLogged] = useState(false);

  const fetchScenarioData = useCallback(async () => {
      try {
          const { data } = await axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/${scenarioId}`, {
              headers: { Authorization: `Bearer ${userId}` },
          });

          if (data) {
              setScenario({
                  title: data.title || '',
                  description: data.description || '',
                  image: data.image || null,
              });
              setIsPrivate(data.isPrivate || false);
              setPassword(data.password || '');
              setIsLogged(data.isLogged || false);
              setShowMap(data.showMap || false);
              setTags(data.tags || []);
              setInitialData(data.description);
              
              // Track the original logged status
              setOriginalIsLogged(data.isLogged || false);

              if (!data.isEditing) {
                  await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/${scenarioId}`, { isEditing: true });
              }
          }
      } catch (error) {
          console.error('Error fetching scenario data:', error);
          if (error.response?.status === 403) {
              navigate('/home');
          } else {
              alert('An error occurred while verifying ownership.');
          }
      }
  }, [scenarioId, userId, navigate]);


  useEffect(() => {
    fetchScenarioData();
  }, [fetchScenarioData]);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.setData(initialData);
    }
  }, [initialData]);

  const handleRemoveTag = useCallback((tagToRemove) => {
    setTags((prevTags) => prevTags.filter((tag) => tag !== tagToRemove));
  }, []);

  const handleAddTag = useCallback((event) => {
    const value = event.target.value.trim().toLowerCase();
    if ((event.key === ' ' || event.key === ',') && value && !tags.includes(value)) {
      setTags((prevTags) => (prevTags.length < 6 ? [...prevTags, value] : prevTags));
      setInputValue('');
      event.preventDefault();
    }
    if (event.key === 'Backspace' && !inputValue) {
      setTags((prevTags) => prevTags.slice(0, -1));
    }
  }, [tags, inputValue]);

  const handleInputChange = useCallback((event) => {
    const { name, value } = event.target;
    setScenario((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleCheckboxChange = useCallback(() => {
    setIsPrivate((prev) => {
      if (prev) setPassword('');
      return !prev;
    });
  }, []);

  const handleLoggedChange = useCallback(() => {
    setIsLogged((prev) => !prev);
  }, []);

  const handleShowMapChange = useCallback(() => {
    setShowMap((prev) => !prev);
  }, []);

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    try {
        const formData = new FormData();
        formData.append('title', scenario.title);
        formData.append('description', scenario.description);
        formData.append('tags', tags.join(','));
        formData.append('isPrivate', isPrivate);
        formData.append('password', password);
        formData.append('isLogged', isLogged);
        formData.append('showMap', showMap);
        if (scenario.image) formData.append('image', scenario.image);

        // Update the scenario
        await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/${scenarioId}`, formData);
        console.log("Scenario updated successfully");

        // Check if isLogged has changed
        if (isLogged !== originalIsLogged) {
            if (isLogged) {
                // Fetch situations and update them to logged (if isLogged was switched to true)
                const { data: situations } = await axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/situations/${scenarioId}/all`, {
                    headers: { Authorization: `Bearer ${userId}` },
                });

                await Promise.all(
                    situations.map((situation) =>
                        axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/situations/situation/${situation._id}`, { isLogged: true })
                    )
                );
            } else {
                // If switched to unlogged, set all situations' isLogged to false
                const { data: situations } = await axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/situations/${scenarioId}/all`, {
                    headers: { Authorization: `Bearer ${userId}` },
                });

                await Promise.all(
                    situations.map((situation) =>
                        axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/situations/situation/${situation._id}`, { isLogged: false })
                    )
                );
            }
        }

        navigate(`/create/${scenarioId}/canvas`);
    } catch (error) {
        console.error('Error updating scenario:', error);
    }
  }, [scenario, tags, isPrivate, password, isLogged, showMap, scenarioId, userId, originalIsLogged, navigate]);



  const tagInput = useMemo(() => (
    <input
      type="text"
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
      spellCheck="false"
      ref={inputRef}
      onKeyDown={(e) => handleAddTag(e)}
    />
  ), [inputValue, handleAddTag]);

  return (
    <div className="scenario-form-container">
      <h1 style={{ marginBottom: '20px' }}>Edit your Title Page</h1>
      <form style={{ display: 'flex', flexDirection: 'column', paddingBottom: '30px' }} onSubmit={handleSubmit}>
        <div className='image-upload-container'>
          <div {...getRootProps()} className='dropzone'>
            <input {...getInputProps()} />
            {scenario.image ? (
              <div className="image-preview">
                <img src={typeof scenario.image === 'string' ? scenario.image : URL.createObjectURL(scenario.image)} alt="Scenario" />
                <button type="button" onClick={() => deleteImageFromServer()}>Remove Image</button>
              </div>
            ) : (
              <span>+</span>
            )}
          </div>
        </div>
        <input
          id="fileInput"
          type="file"
          style={{ display: 'none' }}
          onChange={(e) => handleImageChange(e)}
        />
        <label>Title</label>
        <input
            type="text"
            name="title"
            autoFocus={true}
            value={scenario.title}
            onChange={handleInputChange}
            className="scenario-title-input"
            maxLength={60} // Set the maximum characters to 60
        />
        <div className="char-count">
            {60 - scenario.title.length} characters remaining
        </div>

        <label>Description</label>
        <CKEditor
          editor={ClassicEditor}
          data={initialData}
          plugins={[CKFinderUploadAdapter, MediaEmbed]}
          config={{
            ckfinder: {
              uploadUrl: `${process.env.REACT_APP_API_URL_LOCAL}/api/images/upload`,
            },
            mediaEmbed: { elementName: 'iframe' },
          }}
          onReady={(editor) => (editorRef.current = editor)}
          onChange={(event, editor) => setScenario((prev) => ({ ...prev, description: editor.getData() }))}
        />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label>
            <input
              type="checkbox"
              checked={isPrivate}
              onChange={handleCheckboxChange}
            />
            Set to Private
          </label>
          {isPrivate && (
            <>
              <label>Enter a password</label>
              <input
                type="text"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </>
          )}
          <label>
            <input
              type="checkbox"
              checked={isLogged}
              onChange={handleLoggedChange}
            />
            Create logs
          </label>
          <label>
            <input
              type="checkbox"
              checked={showMap}
              onChange={handleShowMapChange}
            />
            Display map
          </label>
        </div>
        <div className="wrapper">
          <div className="title">
            <h2>Tags</h2>
          </div>
          <div className="content">
            <p>Press Space or Comma after each tag</p>
            <ul>
              {tags.map((tag, index) => (
                <li key={index}>
                  {tag}
                  <i onClick={() => handleRemoveTag(tag)}>&times;</i>
                </li>
              ))}
              {tagInput}
            </ul>
          </div>
          <div className="details">
            <p>
              <span>{6 - tags.length}</span> tags are remaining
            </p>
            <button type="button" onClick={() => setTags([])}>Remove All</button>
          </div>
        </div>
        <button className='next-button' type="submit">Next</button>
      </form>
    </div>
  );
};

export default React.memo(ScenarioTitlePage);
