import React, { useContext, useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation, Routes, Route, Outlet } from 'react-router-dom';
import axios from 'axios';
import AuthContext from '../../context/AuthContext';
import styles from './styles/MyClassrooms.module.css'; // import styles
import MyClassroomsSidebar from './MyClassroomsSidebar';
import MyClassroomsNavbar from './MyClassroomsNavbar';
import ClassroomsGrid from './MyClassroomsGrid';
import Modal from './Modal';
import Classroom from './Classroom';
import Folder from './Folder';
import Folders from './Folders';
import { ClassroomContext } from '../../context/ClassroomContext';

const MyClassrooms = () => {
  const [classrooms, setClassrooms] = useState([]);
  const [joinedClassrooms, setJoinedClassrooms] = useState([]);
  const { userId } = useContext(AuthContext);
  const location = useLocation();
  const [myClassroomsTabOpen, setMyClassroomsTabOpen] = useState(true);
  const [isOwner, setIsOwner] = useState(false);
  const { classroom, setClassroomId } = useContext(ClassroomContext);
  const prevPathname = useRef('');
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  useEffect(() => {
    const currentPathname = location.pathname;


    if (
      (prevPathname.current !== '/my-classrooms' && currentPathname === '/my-classrooms') ||
      (prevPathname.current.startsWith('/my-classrooms/') && currentPathname === '/my-classrooms') ||
      (prevPathname.current === '/my-classrooms' && currentPathname.startsWith('/my-classrooms/')) ||
      true
    ) {
      const fetchClassrooms = async () => {
        try {
          if (userId) {
            const response = await axios.get(
              `${process.env.REACT_APP_API_URL_LOCAL}/api/classrooms/user/${userId}`
            );
            setClassrooms(response.data);
          }
        } catch (error) {
          console.log("Error fetching classrooms: ", error);
        }
      };

      const fetchJoinedClassrooms = async () => {
        try {
          if (userId) {
            const response = await axios.get(
              `${process.env.REACT_APP_API_URL_LOCAL}/api/users/${userId}/joined`
            );
            setJoinedClassrooms(response.data.joinedClassrooms);
          }
        } catch (error) {
          console.log("Error fetching joined classrooms: ", error);
        }
      };

      fetchClassrooms();
      fetchJoinedClassrooms();
    }

    prevPathname.current = currentPathname;

    if (currentPathname === '/my-classrooms' || currentPathname === '/my-classrooms/') {
      setMyClassroomsTabOpen(true);
    } else {
      setMyClassroomsTabOpen(false);
    }
  }, [userId, location.pathname]);

  return (
    <div className={styles.myClassroomsContainer}>
      <div className={styles.classroomNavbar}>
        <MyClassroomsNavbar classroom={classroom} />
      </div>
      <div className={styles.classroomBody}>
        <div className={styles.sidebar}>
          <MyClassroomsSidebar
            classrooms={classrooms}
            joinedClassrooms={joinedClassrooms}
            myClassroomsTabOpen={myClassroomsTabOpen}
            setMyClassroomsTabOpen={setMyClassroomsTabOpen} />
        </div>
        <div className={styles.classroomsGrid}>
          <Routes>
            <Route path="/" element={<ClassroomsGrid classrooms={classrooms} joinedClassrooms={joinedClassrooms} setClassrooms={setClassrooms} setMyClassroomsTabOpen={setMyClassroomsTabOpen} />} />
            <Route path="/:classroomId/*" element={<Classroom />} />
          </Routes>
        </div>

      </div>
    </div>
  );

};

export default MyClassrooms;
