import React, { useContext, useEffect } from 'react';
import AuthContext from "../../../context/AuthContext";


import "./styles/HomePage.css";
import { Helmet } from 'react-helmet';
import ScenarioCarousels from './ScenarioCarousels';

const HomePage = () => {
  const { loggedIn, userId } = useContext(AuthContext);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    loggedIn === true && (
      <div className="HomePage">
        <Helmet>
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7008167333969476" crossorigin="anonymous"></script>
        </Helmet>
        <ScenarioCarousels userId={userId} />
      </div>
    )
  );
};

export default HomePage;
