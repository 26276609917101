// Modal.js
import React, { useState, useEffect } from 'react';
import styles from './styles/Modal.module.css';
import profileIcon from '../../images/profileicon.png';
import { FaSearch, FaClipboard } from 'react-icons/fa';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKFinderUploadAdapter, MediaEmbed } from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';

const Modal = ({ isOpen, onClose, onSubmit, operation, name, type, onImageUpload, inviteUserToClassroom, role, classroomId,
                searchUser, searchTerm, setSearchTerm, searchResults, setSearchResults, handleNameChange,
                allScenarios, addScenariosToFolder, scenarios, announcement, handleStopEditing, newText, setNewText, children }) => {



  const [selectedUsers, setSelectedUsers] = useState([]);
  const [scenariosToBeAdded, setScenariosToBeAdded] = useState([]);
  const [editor, setEditor] = useState(null);
  const [inviteLink, setInviteLink] = useState('');
  const [copied, setCopied] = useState(false);
  const [scenarioSearchTerm, setScenarioSearchTerm] = useState('');





  useEffect(() => {
    if (editor && announcement) {
      editor.setData(announcement.text);
    }
  }, [editor, announcement]);

  useEffect(() => {
    if (isOpen) {
      setScenariosToBeAdded(scenarios);
    }
  }, [isOpen, scenarios]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (onImageUpload) {
      onImageUpload(file);
    }
  };
  

  const toggleUserSelection = (user) => {
    if (selectedUsers.some(u => u._id === user._id)) {
      setSelectedUsers(prev => prev.filter(u => u._id !== user._id));
    } else {
      setSelectedUsers(prev => [...prev, user]);
    }
  };

  const toggleScenarioSelection = (scenario) => {
    if (scenariosToBeAdded.some(s => s._id === scenario._id)) {
      // Remove scenario from the list
      const filteredScenarios = scenariosToBeAdded.filter(s => s._id !== scenario._id);
      setScenariosToBeAdded(filteredScenarios);
    } else {
      // Add scenario to the list
      setScenariosToBeAdded([...scenariosToBeAdded, scenario]);
    }
  };
  
  

  const inviteAllSelectedUsers = async () => {
    for (const user of selectedUsers) {
      await inviteUserToClassroom(user._id, role);
    }
    setSelectedUsers([]);  // Clear selected users after inviting
  };
  
  const addScenariosToBeAdded = async () => {
    await addScenariosToFolder(scenariosToBeAdded);
  }

  const generateInviteLink = async () => {
    try {
      console.log("wjat teh fcuk")
      const response = await axios.post(`${process.env.REACT_APP_API_URL_LOCAL}/api/classrooms/generateInviteLink`, {
        classroomId: classroomId, // Replace with actual classroom ID
        role: role, // Replace with actual role
      });

      setInviteLink(response.data.inviteLink);
      navigator.clipboard.writeText(response.data.inviteLink);
      setCopied(true);
    } catch (err) {
      console.error('Error generating invite link:', err);
    }
  };

  // Function to handle the search input change
  const handleSearchChange = (event) => {
    setScenarioSearchTerm(event.target.value);
  };

  // Filter and sort scenarios based on the search term
  const filteredScenarios = allScenarios
    ?.filter(scenario => scenario.isPublished)
    .filter(scenario => scenario.title.toLowerCase().includes(scenarioSearchTerm.toLowerCase()))
    .sort((a, b) => a.title.localeCompare(b.title));


  if (!isOpen) return null;

  return (
    <>
      {(operation === 'create' || operation === 'edit') && (
        <div className={styles.modalBackgroundDynamic}>
            <div className={styles.modalDynamic}>
                <div className={styles.modalHeader}>
                    {operation === 'create' ? 'Create' : 'Edit'} {type === 'folder' ? 'Folder' : 'Classroom'}
                </div>
                {children}
                {type !== 'folder' && (
                    <>
                        <label htmlFor="classroomImage">Classroom Image:</label>
                        <input type="file" id="classroomImage" onChange={handleImageChange} />
                    </>
                )}
                <div className={styles.buttonContainer}>
                    <button 
                        onClick={(e) => {
                            e.stopPropagation();
                            onClose();
                        }} 
                        className={styles.cancelButton}
                    >
                        Cancel
                    </button>
                    <button 
                        onClick={(e) => {
                            e.stopPropagation();
                            onSubmit();
                        }}
                        disabled={!name && operation === 'create'}
                        className={styles.submitButton}
                    >
                        {operation === 'create' ? 'Create' : 'Save'}
                    </button>
                </div>
            </div>
        </div>
    )}
    {operation === 'invite' && (
      <div className={styles.modalBackgroundDynamic}>
        <div className={styles.searchModal}>
          <div className={styles.modalHeader}>
            Invite a {role}
          </div>
          <div className={styles.searchBar}>
          <input 
            type="text" 
            placeholder="Search users" 
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyPress={(e) => { if (e.key === 'Enter') searchUser(); }}
            className='modal-input'
          />
            <button className={styles.submitButton} onClick={searchUser} disabled={!searchTerm}><FaSearch/></button>
          </div>

          <div className={styles.categoryContainer}>
            <ul className={styles.list}>
              {searchResults?.map(user => (
                <li 
                  key={user._id} 
                  className={styles.item} 
                  onClick={() => toggleUserSelection(user)}
                  style={selectedUsers.some(u => u._id === user._id) ? { backgroundColor: 'lightblue' } : {}}
                >
                  <img src={user.profilePicture || profileIcon} alt="User Icon" className={styles.profileIcon} /> {user.username}
                </li>
              ))}
            </ul>
          </div>
          
          <div className={styles.buttonContainer}>
            <button
              onClick={(e) => {
                e.stopPropagation();
                onClose();
                setSelectedUsers([]);
                setSearchTerm('');
                setSearchResults(null);
              }}
              className={styles.cancelButton}
            >
              Cancel
            </button>
            <button 
              onClick={(e) => {
                e.stopPropagation();
                inviteAllSelectedUsers();
                onClose();
                setSelectedUsers([]);
                setSearchTerm('');
              }}
              disabled={selectedUsers?.length === 0}
              className={styles.submitButton}
            >
              Invite
            </button>
          </div>

          {/* Generate invite link button */}
          <div className={styles.generateLinkContainer}>
            <button 
              onClick={generateInviteLink} 
              className={styles.generateLinkButton}
            >
              Generate Invite Link
            </button>
            {inviteLink && (
              <div className={styles.inviteLinkContainer}>
                <input 
                  type="text" 
                  value={inviteLink} 
                  readOnly 
                  className={styles.inviteLinkInput}
                />
                <button 
                  onClick={() => {
                    navigator.clipboard.writeText(inviteLink);
                    setCopied(true);
                  }} 
                  className={styles.copyButton}
                >
                  <FaClipboard />
                </button>
                {copied && <span className={styles.copiedText}>Copied!</span>}
              </div>
            )}
          </div>
        </div>
      </div>
      )}
      {operation === 'add-scenario' && (
        <div className={styles.modalBackgroundDynamic}>
            <div className={styles.modalDynamic}>
                {/* Scenario Selection Section */}
                <div className={styles.categoryContainer}>
                    <div className={styles.header}>
                        <h2>Select Scenarios</h2>
                        <input
                            type="text"
                            value={scenarioSearchTerm}
                            onChange={handleSearchChange}
                            placeholder="Search scenarios..."
                            className={styles.searchInput}
                        />
                    </div>
                    <ul className={styles.list}>
                        {filteredScenarios.map(scenario => (
                            <li
                                key={scenario._id}
                                className={styles.item}
                                onClick={() => toggleScenarioSelection(scenario)}
                                style={scenariosToBeAdded.some(s => s._id === scenario._id) ? { backgroundColor: 'lightblue' } : {}}
                            >
                                <img src={scenario?.image} alt="Scenario Image" className={styles.profileIcon} /> {scenario?.title}
                            </li>
                        ))}
                    </ul>
                </div>
                {/* Add & Cancel buttons */}
                <button
                    onClick={(e) => {
                        e.stopPropagation();
                        onClose();
                        setScenariosToBeAdded([]);
                    }}
                    className={styles.cancelButton}
                >
                    Cancel
                </button>

                <button
                    onClick={(e) => {
                        e.stopPropagation();
                        addScenariosToBeAdded();
                        setScenariosToBeAdded([]);
                        onClose();
                    }}
                    className={styles.submitButton}
                >
                    Finish
                </button>
            </div>
        </div>
      )}
      {(operation === 'edit-folder') && (
        <div className={styles.modalBackgroundDynamic}>
          <div className={styles.modalDynamic}>
            {children}
            <div className={styles.buttonContainer}>
              <button 
                onClick={(e) => {
                  e.stopPropagation();
                  onClose();
                }} 
                className={styles.cancelButton}
              >
                Cancel
              </button>
              <button 
                onClick={(e) => {
                  e.stopPropagation();
                  onSubmit();
                }}
                className={styles.submitButton}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
      {operation === 'edit-announcement' && (
        <div className={styles.modalBackgroundDynamic}>
          <div className={styles.modalDynamic}>
            <div className={styles.modalHeader}>
              Edit Announcement
            </div>
            <div className={styles.editAnnouncementContainer}>
              <div style={{ wordBreak: 'break-all', overflowWrap: 'break-word', wordWrap: 'break-word'}}>
                <CKEditor
                    editor={ClassicEditor}
                    data={announcement.text}
                    plugins={[ CKFinderUploadAdapter, MediaEmbed ]}
                    config={{
                    ckfinder: {
                        uploadUrl: `${process.env.REACT_APP_API_URL_LOCAL}/api/images/upload`
                    },
                    mediaEmbed: {
                        elementName: 'iframe',

                    }
                    }}
                    onReady={(editor) => {
                    setEditor(editor);
                    }}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        setNewText(data);
                    }}
                />
              </div>
            </div>
            <div className={styles.buttonContainer}>
              <button 
                onClick={(e) => {
                  e.stopPropagation();
                  onClose();
                }}
                className={styles.cancelButton}
              >
                Cancel
              </button>
              <button 
                onClick={(e) => {
                  e.stopPropagation();
                  handleStopEditing(announcement._id, newText);
                  onClose();
                }}
                className={styles.submitButton}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}


      
    </>
  );
};

export default Modal;
