import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from "./styles/ClassroomNavbar.module.css";
import settingsIcon from "../../images/settingsicon.png";
import hamburgerIcon from '../../images/hamburgericon.png'; // Ensure the icon is correctly placed in the images folder

const ClassroomNavbar = ({ classroomId, classroomName, isTeacher}) => {
  const [submenu, setSubmenu] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const location = useLocation();

  const tabs = ['a', 'f', 'p', 'settings', 'i', 'd', 'l'];

  useEffect(() => {
    const updateSubmenu = () => {
      const currentPath = location.pathname;
      let found = false;

      for (const tab of tabs) {
        if (currentPath.includes(`/${tab}`)) {
          setSubmenu(tab);
          found = true;
          break;
        }
      }

      if (!found) {
        setSubmenu('');
      }
    };

    updateSubmenu();
  }, [location, tabs]);

  return (
    <div className={styles.container}>
      <div className={styles.classroomName}>
        {classroomName}
      </div>
      <div className={styles.tabsContainer}>
        <div className={styles.tabs}>
          {/* Existing tabs */}
          <Link to={`/my-classrooms/${classroomId}/a`} className={`${styles.tab} ${submenu === 'a' ? styles.active : ''}`}>Announcements</Link>
          <Link to={`/my-classrooms/${classroomId}/f`} className={`${styles.tab} ${submenu === 'f' ? styles.active : ''}`}>Folders</Link>
          <Link to={`/my-classrooms/${classroomId}/p`} className={`${styles.tab} ${submenu === 'p' ? styles.active : ''}`}>People</Link>
          <Link to={`/my-classrooms/${classroomId}/i`} className={`${styles.tab} ${submenu === 'i' ? styles.active : ''}`}>About</Link>
          {isTeacher && (
            <Link to={`/my-classrooms/${classroomId}/l`} className={`${styles.tab} ${submenu === 'l' ? styles.active : ''}`}>Logs</Link>
          )}
          <div className={styles.iconContainer}>
            {isTeacher && (
              <Link to={`/my-classrooms/${classroomId}/settings`} className={`${styles.dropdownItem} ${styles.about}`} onClick={() => setShowDropdown(false)}>
                <img src={settingsIcon} alt="Settings" className={`${styles.settingsIcon}`} />
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassroomNavbar;
