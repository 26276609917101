import React, { useState, useEffect } from "react";
import axios from 'axios';
import Modal from "./Modal";
import styles from "./styles/ClassroomAnnouncements.module.css";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKFinderUploadAdapter, MediaEmbed } from '@ckeditor/ckeditor5-build-classic';

const ClassroomAnnouncements = ({ isTeacher, userId, classroomId, announcements, setAnnouncements, students,
  classroomName, refreshData, setRefreshData }) => {

  const [newAnnouncementText, setNewAnnouncementText] = useState("");
  const [newText, setNewText] = useState(""); // Add this line
  const [isOpen, setIsOpen] = useState(false);
  const [announcementToEdit, setAnnouncementToEdit] = useState(null);
  const [openedDropdown, setOpenedDropdown] = useState(false);
  const [editor, setEditor] = useState(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click was outside of the dropdown and the optionsButton
      if (openedDropdown && !event.target.closest(`.${styles.optionsDropdown}`) && !event.target.closest(`.${styles.optionsButton}`)) {
        setOpenedDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [openedDropdown, styles.optionsDropdown, styles.optionsButton]);

  useEffect(() => {
    if (editor) {
      editor.setData('');
    }
  }, [editor, announcements]);

  // Utility function to clean the text
  const cleanText = (text) => {
    let cleanedText = text.replace(/<[^>]*>?/gm, '');
    cleanedText = cleanedText.replace(/&nbsp;/gi, ' ');
    return cleanedText;
  };



  const handleNewAnnouncement = async () => {
    if (newAnnouncementText.trim() === "") return;

    // Clean the announcement text
    const cleanedAnnouncementText = cleanText(newAnnouncementText);


    if (userId) {
      const newAnnouncement = {
        poster: userId,
        text: newAnnouncementText, // Use the cleaned text for the announcement
      };

      try {
        if (classroomId) {
          const response = await axios.patch(
            `${process.env.REACT_APP_API_URL_LOCAL}/api/classrooms/${classroomId}`,
            {
              announcements: [...announcements, newAnnouncement]
            }
          );
          if (response.status === 200) {
            setRefreshData(!refreshData);
            setAnnouncements([...announcements, newAnnouncement]);
            setNewAnnouncementText("");

            // New code to send notifications to students
            try {
              await axios.post(`${process.env.REACT_APP_API_URL_LOCAL}/api/notifications/sendAnnouncementToStudents`, {
                students: students, // assuming 'students' is an array of student IDs
                classroomId: classroomId,
                classroomName: classroomName, // replace with actual classroom name
                announcementText: newAnnouncementText, // Send the cleaned text
              });
            } catch (err) {
              console.error("An error occurred while sending announcement notifications:", err);
            }
          } else {
            // Handle error here
            console.error("Failed to update announcements");
          }
        }
      } catch (err) {
        // Handle error here
        console.error("An error occurred while updating announcements:", err);
      }
    }
  };



  const handleStopEditing = async (id, newText) => {

    // Make sure the new text isn't empty before proceeding
    if (newText.trim() === '') {
      return;
    }

    // Create a new array of announcements where the text of the announcement with the matching id is updated
    const updatedAnnouncements = announcements.map((announcement) =>
      announcement._id === id ? { ...announcement, text: newText } : announcement
    );


    try {
      // Update the backend
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL_LOCAL}/api/classrooms/${classroomId}`,
        {
          announcements: updatedAnnouncements,
        }
      );

      if (response.status === 200) {
        // Update local state
        setAnnouncements(updatedAnnouncements);
      } else {
        console.error("Failed to update the announcement.");
      }
    } catch (err) {
      console.error("An error occurred while updating the announcement:", err);
    }
    // Close the editor
  };




  const handleDeleteAnnouncement = async (announcementToDelete) => {
    const updatedAnnouncements = announcements.filter(
      (announcement) => announcement !== announcementToDelete
    );

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL_LOCAL}/api/classrooms/${classroomId}`,
        { announcements: updatedAnnouncements }
      );
      if (response.status === 200) {
        setAnnouncements(updatedAnnouncements);
      } else {
        console.error("Failed to delete announcement");
      }
    } catch (err) {
      console.error("An error occurred while deleting announcements:", err);
    }
  };

  const transformIframes = (html) => {
    if (typeof html !== 'string') {
      return '';
    }
    return html.replace(/<iframe url="https:\/\/www.youtube.com\/watch\?v=([^"]+)"><\/iframe>/g, (match, videoId) => {
      return `<iframe width="560" height="315" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allowfullscreen></iframe>`;
    });
  };

  //   const transformedDescription = transformIframes(scenario?.description);




  return (
    <div className={styles.announcementsContainer}>
      {isOpen && (
        <Modal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          operation="edit-announcement"
          announcement={announcementToEdit}
          handleStopEditing={handleStopEditing}
          newText={newText}
          setNewText={setNewText}
        />
      )}
      {isTeacher && (
        <div className={styles.createAnnouncementContainer}>
          Announce something to your class:

          <div style={{ wordBreak: 'break-all', overflowWrap: 'break-word', wordWrap: 'break-word' }}>
            <CKEditor
              editor={ClassicEditor}
              data=''
              plugins={[CKFinderUploadAdapter, MediaEmbed]}
              config={{
                ckfinder: {
                  uploadUrl: `${process.env.REACT_APP_API_URL_LOCAL}/api/images/upload`
                },
                mediaEmbed: {
                  elementName: 'iframe',

                }
              }}
              onReady={(editor) => {
                setEditor(editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setNewAnnouncementText(data);
              }}
            />
          </div>
          <div className={styles.postAnnouncement}>
            <button onClick={() => {
              handleNewAnnouncement();
            }}>Submit</button>
          </div>
        </div>
      )}


      <div className={styles.announcementsGrid}>
        {[...announcements].reverse().map((announcement) => (
          <div key={announcement?._id} className={styles.announcement}>
            <div className={styles.username}>{announcement.poster.username}</div><br />
            <p dangerouslySetInnerHTML={{ __html: transformIframes(announcement.text) }} />
            {isTeacher && (
              <button className={styles.optionsButton} onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setOpenedDropdown(announcement._id === openedDropdown ? null : announcement._id);
              }} />
            )}
            {openedDropdown === announcement._id && (
              <div className={styles.optionsDropdown}>
                <div className={styles.optionsDropdownItem} onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setIsOpen(true);
                  setAnnouncementToEdit(announcement);
                  setOpenedDropdown(false);
                }}>
                  Edit
                </div>
                <div className={styles.optionsDropdownItem} onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleDeleteAnnouncement(announcement);
                  setOpenedDropdown(false);
                }}>
                  Delete
                </div>
              </div>
            )}
          </div>
        ))}
      </div>


    </div>
  );
};

export default ClassroomAnnouncements;
