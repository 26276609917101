import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './styles/AcceptInvite.module.css';
import AuthContext from '../../context/AuthContext';

const AcceptInvite = () => {
  const { userId } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    setToken(token);

    const verifyToken = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/notifications/verifyInviteToken`, { params: { token } });
        if (response.data.valid) {
          if (userId) {
            await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/notifications/acceptInviteFromLink`, { token });
            navigate(`/my-classrooms/${response.data.classroomId}/a`);
          } else {
            navigate(location.pathname, { state: { showModal: true, authModalState: 'sign up', acceptInvite: true, from: location.pathname, token, classroomId: response.data.classroomId } });
          }
        } else {
          setError('Invalid or expired invite link.');
        }
      } catch (err) {
        setError('Invalid or expired invite link.');
      } finally {
        setIsLoading(false);
      }
    };

    if (token) {
      verifyToken();
    } else {
      setIsLoading(false);
    }
  }, [userId, location.pathname, location.search, navigate]);

  if (isLoading) {
    return <div>Loading...</div>; // Render loading until the token is verified
  }

  if (error) {
    return <div className={styles.error}>{error}</div>;
  }

  return <div>Loading...</div>; // Automatically navigating if the user is logged in
};

export default AcceptInvite;
