import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useParams, useNavigate, Link } from 'react-router-dom';
import styles from './styles/Folders.module.css';
import Modal from './Modal';
import folderIcon from '../../images/foldericon.png';
import addFolderIcon from '../../images/addfoldericon.png';
import addScenarioIcon from '../../images/addfileicon.png';
import settingsIcon from '../../images/settingsicon.png';
import modalStyles from './styles/Modal.module.css';
import profileIcon from '../../images/profileicon.png';



const Folder = ({isTeacher, students, userId, teachers}) => {
  const { classroomId, folderId } = useParams();
  const [folder, setFolder] = useState(null);
  const [subfolders, setSubfolders] = useState([]);
  const [scenarios, setScenarios] = useState([]);
  const [allScenarios, setAllScenarios] = useState([]);
  const [isScenarioModalOpen, setIsScenarioModalOpen] = useState(false);
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [newSubfolderName, setNewSubfolderName] = useState('');
  const [editSubfolderName, setEditSubfolderName] = useState('');
  const [error, setError] = useState(null);

  const [editingFolder, setEditingFolder] = useState(null);
  const [openedDropdown, setOpenedDropdown] = useState(false);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [parentAllowedStudents, setParentAllowedStudents] = useState([]);

  const navigate = useNavigate();
  

  useEffect(() => {
    if (folderId) {
      axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/folders/populated/${folderId}`)
      .then(response => {
        setFolder(response.data);
        setScenarios(response.data.scenarios);
        // setScenariosToBeAdded(response.data.scenarios);
        setSubfolders(response.data.folders);
        setParentAllowedStudents(response.data.allowedStudents);
      })
      .catch(error => {
        console.error(`Error: ${error}`);
      });
    }
  }, [folderId]);

  useEffect(() => {
    if (userId) {
      axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/my`)
      .then(response => {
        setAllScenarios(response.data);
      })
      .catch(error => {
        console.error(`Error: ${error}`);
      });
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
        // Check if the click was outside of the dropdown and the optionsButton
        if (openedDropdown && !event.target.closest(`.${styles.optionsDropdown}`) && !event.target.closest(`.${styles.optionsButton}`)) {
            setOpenedDropdown(false);
        }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
        document.removeEventListener("click", handleClickOutside);
    };
  }, [openedDropdown, styles.optionsDropdown, styles.optionsButton]);

  useEffect(() => {
    // Initialize the input field when the modal is opened and editingFolder is set
    if (editingFolder) {
      setEditSubfolderName(editingFolder.name);
    }
  }, [editingFolder, isEditModalOpen]);

  useEffect(() => {
    const targetFolder = subfolders.find(subfolder => subfolder?._id === editingFolder?._id);
    if (targetFolder) {
      const allowedStudentIDs = targetFolder.allowedStudents;
      
      // Map allowedStudentIDs to student objects
      const allowedStudentObjects = parentAllowedStudents.filter(student => 
        allowedStudentIDs.includes(student?._id)
      );
  
      setSelectedStudents(allowedStudentObjects);
    }
  }, [editingFolder, parentAllowedStudents, subfolders]);

  
  

  const createSubfolder = () => {
    if (newSubfolderName.trim() === "") {
      setError("This field cannot be empty");
      return;
    }
    const data = {
      classroomId,
      name: newSubfolderName,
      students: parentAllowedStudents,
    };
    if (classroomId) data.classroomId = classroomId;
    if (folderId) data.folderId = folderId;
  
    axios.post(`${process.env.REACT_APP_API_URL_LOCAL}/api/folders`, data)
      .then(response => {
        const newFolderId = response.data._id;
  
        setSubfolders([...subfolders, response.data]);
  
        // Navigate to the newly created folder
        if (newFolderId) {

        } else {
          
        }
        setIsFolderModalOpen(false);  // Close the modal
        setNewSubfolderName('');  // Optionally, reset the folder name
      })
      .catch(error => {
        console.log("Error creating folder:", error);
      });
  };

  const addScenariosToFolder = async (scenariosToBeAdded) => {
    const scenarioIds = scenariosToBeAdded.map(scenario => scenario._id);

    try {
        // Update the folder with the scenarios
        await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/folders/${folderId}`, {
            scenarios: scenarioIds
        });

        // Update the classroom with the scenarios
        await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/classrooms/${classroomId}`, {
            scenarios: scenarioIds
        });

        // Update the state and close the modal
        setScenarios(scenariosToBeAdded);
        setIsScenarioModalOpen(false);
    } catch (error) {
        console.log("Error adding scenarios:", error);
    }
  };


  const handleNameChange = (e) => {
    setNewSubfolderName(e.target.value);
  }; 

  const handleEditNameChange = (e) => {
    setEditSubfolderName(e.target.value);
  }; 



  const editFolder = async () => {

    if (editSubfolderName.trim() === "") {
        setError("This field cannot be empty");
        return;
    }

    const updatedAllowedStudents = selectedStudents.map(user => user?._id);

    try {
      const response = await axios.patch(
      `${process.env.REACT_APP_API_URL_LOCAL}/api/folders/${editingFolder._id}`,
      { 
          name: editSubfolderName,
          allowedStudents: updatedAllowedStudents 
      }
    );
    
    // Find and update the classroom
    const updatedFolders = subfolders.map((subfolder) => {
        if (subfolder._id === editingFolder._id) {
          return { ...subfolder, name: editSubfolderName, allowedStudents: updatedAllowedStudents };
        }
        return subfolder;
    });
    

      setSubfolders(updatedFolders); // Update the state

      setIsEditModalOpen(false); // Close modal after successful edit
      setEditSubfolderName("");
      } catch (error) {
        console.log("Error updating classroom:", error);
      }
  };

  const toggleStudentSelection = (student) => {
    if (selectedStudents.some(s => s?._id === student?._id)) {
      const newSelectedStudents = selectedStudents.filter(s => s?._id !== student?._id);
      setSelectedStudents(newSelectedStudents);
    } else {
      setSelectedStudents([...selectedStudents, student]);
    }
  };

  const deleteFolder = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL_LOCAL}/api/folders/${editingFolder._id}`
      );

      // Update the frontend state to remove the folder
      const updatedFolders = subfolders.filter(
        (folder) => folder._id !== editingFolder._id
      );

      setSubfolders(updatedFolders); // Update the state
      setIsEditModalOpen(false); // Close modal after successful delete
    } catch (error) {
      console.log("Error deleting folder:", error);
    }
  };

  const handleFolderClick = (folder, userId, classroomId) => {
    // Check if the userId exists in the teacherIds array
    if (isTeacher) {
      navigate(`/my-classrooms/${classroomId}/f/${folder._id}`);
      return;
    }

    // Check if the userId exists in the folder's allowedStudents array
    if (folder.allowedStudents?.includes(userId)) {
      navigate(`/my-classrooms/${classroomId}/f/${folder._id}`);
      return;
    }

    // The user is neither a teacher nor an allowed student.
    alert("You do not have permission to access this folder.");
  };


  return (
    <div className={styles.foldersContainer}>
      <Modal isOpen={isFolderModalOpen} onClose={() => setIsFolderModalOpen(false)} onSubmit={createSubfolder} operation='create' name={newSubfolderName} type='folder'>
        <label htmlFor="folderName">Create your Folder</label>
        <input
            type="text"
            id="folderName"
            value={newSubfolderName}
            onChange={handleNameChange}
            className='modal-input'
        />
        {error && <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>}
      </Modal>
      <Modal isOpen={isScenarioModalOpen}
        onClose={() => setIsScenarioModalOpen(false)}
        onSubmit={addScenariosToFolder}
        operation='add-scenario'
        allScenarios={allScenarios}
        addScenariosToFolder={addScenariosToFolder}
        scenarios={scenarios}
      >
        <label htmlFor="title">Add a Scenario</label>
      </Modal>
      <Modal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)} onSubmit={editFolder} operation='edit-folder' name={editSubfolderName} type='folder'>
          <label htmlFor="subfolderName">Edit your Folder</label>
          <input type="text" id="subfolderName" className='modal-input' value={editSubfolderName} onChange={handleEditNameChange} />
          <div className={styles.listHeader}>
              The following students have access to this folder:
          </div>
          <ul className={modalStyles.list}>
              {parentAllowedStudents?.map(student => (
              <li 
                  key={student?._id} 
                  onClick={() => toggleStudentSelection(student)}
                  style={selectedStudents.some(s => s?._id === student?._id) ? { backgroundColor: 'lightblue' } : {}}
                  className={modalStyles.item}
              >
                  <img src={profileIcon} alt="User Icon" className={modalStyles.profileIcon} /> {student?.username}
              </li>
              ))}
          </ul>

          {error && <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>}
          <button onClick={deleteFolder} className={styles.deleteButton}>Delete Folder</button>
      </Modal>
      <div className={styles.foldersHeader}>
          <h2 className={styles.title}>{folder?.name}</h2>
          {isTeacher && (
            <div className={styles.buttonContainer}>
              <img src={addScenarioIcon} className={styles.addScenarioIcon} onClick={() => setIsScenarioModalOpen(true)}/>
              <img src={addFolderIcon} className={styles.addFolderIcon} onClick={() => setIsFolderModalOpen(true)}/>
            </div>
          )}
      </div>

      <div className={styles.foldersBody}>
        {subfolders.map((folder, index) => (
          <div key={index} className={styles.folderItem}>
            <div
              onClick={() => handleFolderClick(folder, userId, classroomId)}
              style={{ textDecoration: 'none', color: 'inherit', cursor:'pointer' }}
            >
              <img src={folderIcon} className={styles.folderItemImage} alt="folder icon" />
              <div className={styles.folderName}>{folder?.name}</div>
            </div>
            {isTeacher && (
              <img 
                src={settingsIcon} 
                className={styles.optionsButton} 
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenedDropdown(folder._id === openedDropdown ? null : folder._id);
                  setIsEditModalOpen(true);
                  setEditingFolder(folder);
                }} 
              />
            )}
          </div>
        ))}

        {scenarios.map((scenario, index) => (
            <div key={index} className={styles.folderItem} onClick={() => navigate(`/view/${scenario._id}`, { state: { hasAccess: true } })}>
                <img src={scenario?.image} className={styles.folderItemImage} alt="folder icon" />
                <div className={styles.folderName}>{scenario?.title}</div>
            </div>
        ))}
      </div>
    </div>
    );
};

export default Folder;
