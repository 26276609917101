import React, { useEffect, useState } from 'react';
import axios from 'axios';
import GroupBody from './GroupBody';
import SituationBody from './SituationBody';
import ChoicesBody from './ChoicesBody';
import UserSubmittedBody from './UserSubmittedBody';
import FreeResponseBody from './FreeResponseBody';
import styles from './styles/ViewSituation.module.css';
import { useNavigate } from 'react-router-dom';
import { useScenario } from '../../../context/ScenarioContext';
import FeedbackModal from './FeedbackModal';
import { FaCommentDots } from 'react-icons/fa';
import ViewCanvas from './ViewCanvas';
import { FaExpand, FaCompress } from 'react-icons/fa';

const ViewSituation = ({ userId, scenario, canvas, currentSituationId, setCurrentSituationId, preview, userLoading }) => {
    const [currentSituation, setCurrentSituation] = useState(null);
    const navigate = useNavigate();
    const { addDecisionToLog, completeLog, setLogId } = useScenario();
    const [feedbackText, setFeedbackText] = useState();
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [isMinimapExpanded, setIsMinimapExpanded] = useState(false);

    useEffect(() => {
        const fetchCurrentSituation = async () => {
            if (currentSituationId && scenario && !userLoading) { // Add userLoading condition
                try {
                    const situationResponse = await axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/situations/situation/${currentSituationId}`);
                    setCurrentSituation(situationResponse.data);
                    if (scenario.isLogged && situationResponse.data.isLogged && !preview) {
                        await addDecisionToLog({ 
                            type: 'situation', 
                            id: situationResponse.data._id, 
                            title: situationResponse.data.title 
                        });
                    }
                } catch (error) {
                    console.error(`Error: ${error}`);
                }
            }
        };
        fetchCurrentSituation();
    }, [currentSituationId, userLoading]); // Add userLoading as a dependency

    const handleFinish = async () => {
        if (scenario.isLogged) {
            await completeLog();
        }
        await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/${scenario._id}/completions`);
        setCurrentSituationId(null);
        navigate('/');
    };

    const closeFeedbackModal = () => {
        setShowFeedbackModal(false);
        setFeedbackText('');
    };

    const renderConditionalBody = () => {
        if (!currentSituation) {
            return <div>Loading...</div>;
        }
        if (currentSituation.isUserSubmitted) {
            return (
                <UserSubmittedBody
                    situation={currentSituation} 
                    setCurrentSituationId={setCurrentSituationId}
                    isScenarioLogged={scenario.isLogged} 
                    preview={preview}
                />
            );
        } else if (currentSituation.isFreeResponse) {
            return (
                <FreeResponseBody
                    situation={currentSituation}
                    setCurrentSituationId={setCurrentSituationId}
                    isScenarioLogged={scenario.isLogged}
                    preview={preview}
                />
            );
        } else if (currentSituation.choices && currentSituation.choices.length > 0) {
            return (
                <ChoicesBody
                    choices={currentSituation.choices}
                    setCurrentSituationId={setCurrentSituationId}
                    isScenarioLogged={scenario.isLogged}
                    preview={preview}
                />
            );
        } else if (currentSituation.isEnd && !preview) {
            return (
                <button className={styles.button} onClick={handleFinish}>Home</button>
            );
        }
        return null;
    };

    const handleToggleMinimap = () => {
        setIsMinimapExpanded(!isMinimapExpanded);
    };

    
    if (!currentSituation) {
        return <div>Loading...</div>;
    } else {
        return (
            <div className={`${styles.container} ${preview ? styles.previewPadding : ''}`}>
                {currentSituation?.situationGroup && (
                    <GroupBody group={currentSituation.situationGroup} />
                )}
        
                <SituationBody situation={currentSituation} />
                {renderConditionalBody()}
                {(scenario.showMap || preview) && (
                    <div className={isMinimapExpanded ? styles.canvasExpanded : styles.canvasMinimap}>
                        <ViewCanvas canvas={canvas} currentSituationId={currentSituationId} isMinimapExpanded={isMinimapExpanded} />
                        <div className={styles.toggleButton} onClick={handleToggleMinimap}>
                            {isMinimapExpanded ? <FaCompress /> : <FaExpand />}
                        </div>
                    </div>
                )}
                
    
                {showFeedbackModal && (
                    <FeedbackModal
                        userId={userId}
                        closeFeedbackModal={closeFeedbackModal}
                        feedbackText={feedbackText}
                        setFeedbackText={setFeedbackText}
                        scenario={scenario}
                        situation={currentSituation}
                    />
                )}

                <FaCommentDots
                    className={styles.feedbackButton}
                    onClick={() => setShowFeedbackModal(true)}
                />
            </div>
        );
    }

};

export default ViewSituation;
