import React from 'react';
import Masonry from 'react-masonry-css';
import ScenarioCard from './ScenarioCard';
import styles from './styles/ScenarioCarousel.module.css';

const ScenarioCarousel = ({ scenariosList }) => {

    const breakpointColumnsObj = {
        default: 5,
        1400: 4,
        1100: 3,
        800: 2,
        500: 1
    };

    return (
        <Masonry
            breakpointCols={breakpointColumnsObj}
            className={styles.myMasonryGrid}
            columnClassName={styles.myMasonryGridColumn}
        >
            {scenariosList.map((scenario) => (
                <ScenarioCard 
                    key={scenario._id} 
                    scenario={scenario}
                />
            ))}
        </Masonry>
    );
};

export default ScenarioCarousel;
