import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ReactFlowProvider } from 'react-flow-renderer';
import CreateSidebar from './nCreateSidebar';
import Canvas from './nCanvas';
import EditSidebar from './nEditSidebar';
import { CanvasProvider } from '../../../context/CanvasContext';
import styles from './styles/CreateScenario.module.css';
import axios from 'axios';

const CreateScenario = () => {
    const { scenarioId } = useParams();
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Resizable sidebar widths
    const [createSidebarWidth, setCreateSidebarWidth] = useState(20); // In percentage
    const [editSidebarWidth, setEditSidebarWidth] = useState(20); // In percentage

    // Handle dragging to resize the create sidebar
    const handleCreateSidebarResize = (e) => {
        e.preventDefault();
        document.onmousemove = (event) => {
            const newWidth = (event.clientX / window.innerWidth) * 100;
            if (newWidth > 20 && newWidth < 40) { // Limit resize range between 10% and 40%
                setCreateSidebarWidth(newWidth);
            }
        };
        document.onmouseup = () => {
            document.onmousemove = null;
        };
    };

    // Handle dragging to resize the edit sidebar
    const handleEditSidebarResize = (e) => {
        e.preventDefault();
        document.onmousemove = (event) => {
            const newWidth = ((window.innerWidth - event.clientX) / window.innerWidth) * 100;
            if (newWidth > 20 && newWidth < 40) { // Limit resize range between 10% and 40%
                setEditSidebarWidth(newWidth);
            }
        };
        document.onmouseup = () => {
            document.onmousemove = null;
        };
    };

    useEffect(() => {
        const verifyOwnership = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/${scenarioId}/verifyOwnership`);
                setIsAuthorized(true);
                setLoading(false);
            } catch (err) {
                console.error("Ownership verification failed:", err);
                setIsAuthorized(false);
                setLoading(false);
                if (err.response?.status === 403) {
                    setError("Access Denied");
                } else if (err.response?.status === 404) {
                    setError("Scenario not found.");
                } else {
                    setError("Access Denied");
                }
            }
        };

        verifyOwnership();
    }, [scenarioId]);

    if (loading) {
        return <div>Loading...</div>; // Replace with a proper loading spinner eventually
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!isAuthorized) {
        return null;
    }

    return (
        <CanvasProvider scenarioId={scenarioId}>
            <div className={styles.container}>
                {/* CreateSidebar with resizable width */}
                <div
                    className={styles.createSidebar}
                    style={{ width: `${createSidebarWidth}%` }}
                >
                    <CreateSidebar />
                </div>

                {/* Divider for resizing the CreateSidebar */}
                <div
                    className={styles.resizer}
                    onMouseDown={handleCreateSidebarResize}
                />

                <ReactFlowProvider>
                    <div className={styles.canvas} style={{ width: `${100 - createSidebarWidth - editSidebarWidth}%` }}>
                        <Canvas />
                    </div>
                </ReactFlowProvider>

                {/* Divider for resizing the EditSidebar */}
                <div
                    className={styles.resizer}
                    onMouseDown={handleEditSidebarResize}
                />

                {/* EditSidebar with resizable width */}
                <div
                    className={styles.editSidebar}
                    style={{ width: `${editSidebarWidth}%` }}
                >
                    <EditSidebar />
                </div>
            </div>
        </CanvasProvider>
    );
};

export default CreateScenario;
