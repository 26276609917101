import React, { useEffect, useState, useContext } from 'react';
import ViewScenarioTitlePage from './ViewScenarioTitlePage';
import ViewSituation from './ViewSituation';
import { useParams, useMatch, useNavigate, useLocation } from 'react-router-dom';
import { useScenario } from '../../../context/ScenarioContext';
import axios from 'axios';
import ViewTableOfContents from './ViewTableOfContents';
import styles from './styles/ViewScenario.module.css';
import AuthContext from '../../../context/AuthContext';
import ScenarioPassword from './ScenarioPassword';
import { FaListOl, FaRedo } from 'react-icons/fa';

const ViewScenario = () => {
    const location = useLocation();
    const { userId } = useContext(AuthContext); // Only get userId from AuthContext
    const { scenarioId } = useParams();
    const [scenario, setScenario] = useState(null);
    const [canvas, setCanvas] = useState(null); // Add state for canvas
    const { currentSituations, setCurrentSituationId, setLogId, completeLog } = useScenario();
    const [hasAccess, setHasAccess] = useState(location.state?.hasAccess ?? false);
    const [fromFeedback, setFromFeedback] = useState(location.state?.fromFeedback ?? false);
    const [passwordError, setPasswordError] = useState(false);
    const [isToCVisible, setIsToCVisible] = useState(false); // State to manage ToC visibility
    const preview = useMatch("/create/:scenarioId/preview");
    const navigate = useNavigate();

    const [user, setUser] = useState(null); // Add state for user
    const [userLoading, setUserLoading] = useState(true); // Add loading state

    const currentSituationId = location.state?.currentSituationId ?? currentSituations[scenarioId];

    useEffect(() => {
        const fetchUserData = async () => {
            if (!userId) {
                setUserLoading(false);
                return;
            }
            
            try {
                const userResponse = await axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/users/${userId}`);
                setUser(userResponse.data);

                // Check for incomplete logs
                if (userResponse.data && userResponse.data.incompleteLogs) {
                    for (const [logId, log] of Object.entries(userResponse.data.incompleteLogs)) {
                        // console.log("Iterating through incomplete logs: ", log);
                        if (log.scenarioId === scenarioId) {
                            setLogId(logId);
                            // console.log("Found an incomplete log. Setting log ID state variable to the log ID of the incomplete logs!");
                            break;
                        } else {
                            // console.log("The scenarioId does not match any of the incomplete logs. DO NOTHING for now");
                        }
                    }
                } else {
                    // console.log("No user, or no incomplete logs found, or the scenario isn't logged");
                    if (!userResponse.data) {
                        // console.log("No User");
                    } else if (!userResponse.data.incompleteLogs) {
                        // console.log("User has no incomplete logs");
                    }
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            } finally {
                setUserLoading(false); // Set userLoading to false after fetching user data
            }
        };

        fetchUserData();
    }, [userId, scenarioId, setLogId]);

    useEffect(() => {
        const fetchScenarioData = async () => {
            try {
                const scenarioResponse = await axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/${scenarioId}`);
                setScenario(scenarioResponse.data);
                setPasswordError(false);

                // If the Scenario isn't private, user should have access
                if (!scenarioResponse.data.isPrivate || preview) {
                    setHasAccess(true);
                }

                // Fetch the canvas data
                const canvasResponse = await axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/canvas/${scenarioId}`);
                setCanvas(canvasResponse.data);
            } catch (error) {
                console.error(`Error: ${error}`);
            }
        };

        fetchScenarioData();
    }, [scenarioId, preview]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentSituationId]);

    const handleRestart = async () => {
        setCurrentSituationId(scenario._id, null);
    }

    const handleExitPreview = () => {
        navigate(`/create/${scenarioId}/canvas`);
        setCurrentSituationId(scenario._id, null);
    }

    const handleReturnToFeedback = () => {
        if (user) {
            navigate(`/user/${user.username}`, { state: { activeTab: 'Feedback' } });
        }
        setFromFeedback(false);
    }

    const toggleToC = () => {
        setIsToCVisible(!isToCVisible);
    }

    if (!scenario) {
        // Temporary "loading screen" if Scenario is still fetching. May eventually design a <Loader/> or <ScenarioLoader/> component
        return <div>Loading...</div>;
    } else if (!hasAccess) {
        return (
            <ScenarioPassword
                scenarioPassword={scenario.password}
                setHasAccess={setHasAccess}
                passwordError={passwordError}
                setPasswordError={setPasswordError}
            />
        );
    } else {
        return (
            <>
                {preview && (
                    <button className={styles.previewButton} onClick={handleExitPreview}>Exit Preview</button>
                )}
                {fromFeedback && !preview && (
                    <button className={styles.previewButton} onClick={handleReturnToFeedback}>Back</button>
                )}
                {!currentSituationId ? (
                    <>
                        {/* If no current situation id, display the title page */}
                        <ViewScenarioTitlePage 
                            userId={userId}
                            incompleteLogs={user?.incompleteLogs}
                            scenario={scenario}
                            currentSituationId={currentSituationId}
                            setCurrentSituationId={(situationId) => setCurrentSituationId(scenario._id, situationId)}
                            preview={preview}
                        />
                    </>
                ) : (
                    //Otherwise, render ViewSituation, the Table of Contents (if it exists), and the restart button
                    <div className={styles.container}>
                        {scenario.tableOfContents && (
                            <>
                                <FaListOl className={styles.tocButton} onClick={toggleToC} />
                                {isToCVisible && (
                                    <ViewTableOfContents
                                        tableOfContents={scenario.tableOfContents}
                                        currentSituationId={currentSituationId}
                                        setCurrentSituationId={(situationId) => setCurrentSituationId(scenario._id, situationId)}
                                        toggleToC={toggleToC}
                                    />
                                )}
                            </>
                        )}
                        <>
                            <ViewSituation
                                userId={userId}
                                scenario={scenario} 
                                canvas={canvas} // Pass canvas to ViewSituation
                                currentSituationId={currentSituationId}
                                setCurrentSituationId={(situationId) => setCurrentSituationId(scenario._id, situationId)}
                                preview={preview}
                                userLoading={userLoading} // Pass userLoading state
                            />
                        </>
            
                        {/* Restart button */}
                        <FaRedo
                            className={styles.restartButton}
                            onClick={handleRestart}
                        />
                    </div>
                )}
            </>
        );
    }
};

export default ViewScenario;
