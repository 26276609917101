import React, { useState } from 'react';
import axios from 'axios';
import styles from './styles/MyClassroomsNavbar.module.css';
import Modal from './Modal';
import { useNavigate } from 'react-router-dom';
import './styles/ClassroomStyles.css';

const MyClassroomsNavbar = ({ classroom }) => {
  const [error, setError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [image, setImage] = useState(null);

  const handleImageUpload = (uploadedImage) => {
    setImage(uploadedImage);
  };

  const navigate = useNavigate();

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const createClassroom = async () => {
    if (name.trim() === "") {
      setError("This field cannot be empty");
      return;
    }

    const formData = new FormData();
    formData.append('name', name);
    if (image) {
      formData.append('image', image);
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_LOCAL}/api/classrooms`,
        formData
      );

      const classroomId = response.data._id;

      if (classroomId) {
        navigate(`/my-classrooms/${classroomId}`);
      } else {
        console.log("Error: ID is undefined");
      }

      setIsModalOpen(false);
    } catch (error) {
      console.error("Error creating classroom:", error);
    }
  };

  return (
    <>
      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onSubmit={createClassroom} operation="create" name={name} onImageUpload={handleImageUpload}>
          <label htmlFor="classroomName">Name your Classroom</label>
          <input
            type="text"
            id="classroomName"
            value={name}
            onChange={handleNameChange}
            className='modal-input'
          />
          {error && <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>}
        </Modal>
      )}
      <div className={styles.headerContainer}> 
        <h3 className={styles.title}>Classrooms</h3>
        {classroom?.name && (
          <div>
            {classroom?.name}
          </div>
        )}
        <button className={styles.createClassroomButton} onClick={() => setIsModalOpen(true)}>+</button>
      </div>
    </>
  );
};

export default MyClassroomsNavbar;
