import React, { useContext } from 'react';
import styles from './styles/EditSidebar.module.css';
import CanvasContext from '../../../context/CanvasContext';
import EditSituation from './EditSituation';
import EditChoice from './EditChoice';
import EditGroup from './EditGroup';

const EditSidebar = () => {
    const { selectedSituation, selectedEdgeId, selectedGroup } = useContext(CanvasContext);
    

    return (
        <div className={styles.container}>
            {selectedSituation && <EditSituation/>}
            {selectedEdgeId && <EditChoice/>}
            {selectedGroup && <EditGroup/>}
        </div>
    );
};

export default EditSidebar;