import React from 'react';
import { transformIframes } from '../../../utils/utils';
import styles from './styles/GroupBody.module.css';

const GroupBody = ({ group }) => {
    // Function to determine if the file is video or audio
    const isVideo = (file) => {
        const videoExtensions = ['.mp4', '.webm', '.ogg'];
        return videoExtensions.some(ext => file.endsWith(ext));
    };

    return (
        <div className={styles.container}>
            {group.audio && (
                <div className={styles.mediaContainer}>
                    {isVideo(group.audio) ? (
                        <video key={group.audio} controls autoPlay className={styles.media}>
                            <source src={group.audio} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    ) : (
                        <audio key={group.audio} controls autoPlay className={styles.media}>
                            <source src={group.audio} type="audio/mpeg" />
                            Your browser does not support the audio element.
                        </audio>
                    )}
                </div>
            )}
            {group.image && (
                <div className={styles.imageContainer}>
                    <img src={group.image} alt={group.title} className={styles.image} />
                </div>
            )}
            <div
                className={styles.text}
                dangerouslySetInnerHTML={{ __html: transformIframes(group.text) }}
            />
        </div>
    );
};

export default GroupBody;
