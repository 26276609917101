import DOMPurify from 'dompurify';

export function transformIframes(html) {
  if (typeof html !== 'string') {
    return '';
  }

  // Sanitize the HTML to remove any potentially harmful code
  const sanitizedHtml = DOMPurify.sanitize(html);

  // Replace custom iframe tags with actual YouTube embed iframes
  const transformedHtml = sanitizedHtml.replace(/<iframe url="https:\/\/www.youtube.com\/watch\?v=([^"]+)"><\/iframe>/g, (match, videoId) => {
    return `<iframe width="560" height="315" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allowfullscreen></iframe>`;
  });

  return transformedHtml;
}

export function stripHtml(html) {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || "";
};

export function calculateTimeSince(date) {
  const now = Date.now();
  const created = new Date(date).getTime();
  const diffInSeconds = Math.floor((now - created) / 1000);
  let unit = 'second';
  let diffInUnit = diffInSeconds;
  const units = {
    year: 31536000,
    month: 2628000,
    day: 86400,
    hour: 3600,
    minute: 60,
  };
  for (const [unitKey, secondsInUnit] of Object.entries(units)) {
    if (diffInSeconds >= secondsInUnit) {
      unit = unitKey;
      diffInUnit = Math.floor(diffInSeconds / secondsInUnit);
      break;
    }
  }
  if (diffInUnit !== 1) {
    unit += 's';
  }
  return `${diffInUnit} ${unit}`;
};

export function checkValidity(nodes, edges) {
  let startNodes = nodes.filter(node => node.data.endpointType === 'start');
  let endNodes = nodes.filter(node => node.data.endpointType === 'end');

  console.log("START NODES: ", startNodes);
  
  const errors = [];
  const visited = new Set();
  const recursionStack = new Set();
  const pathSuccess = new Map();
  
  if (startNodes.length === 0) {
      errors.push({ reason: 'No start node found' });
      return errors;
  }
  if (startNodes.length > 1) {
      startNodes.forEach(node => errors.push({ node, reason: 'Multiple start nodes' }));
  }
  if (endNodes.length === 0) {
      errors.push({ reason: 'No end node found' });
      return errors;
  }

  function hasPathToEnd(nodeId) {
      let stack = [nodeId];
      let visited = new Set(); // Start with the nodes in the current cycle
  
      while (stack.length > 0) {
          let currentNodeId = stack.pop();
          let currentNode = nodes.find(n => n.id === currentNodeId);
  
          // Only mark as visited after ensuring all paths are explored
          visited.add(currentNodeId);
  
          if (currentNode.data.endpointType === 'end') {
              return true;
          }
  
          let outgoingEdges = edges.filter(edge => edge.source === currentNodeId && !visited.has(edge.target));
          outgoingEdges.forEach(edge => {
              stack.push(edge.target); // Push first, visit later
          });
      }
      return false;
  }
  

  function dfs(node, path = []) {
      let currentPathSuccess = pathSuccess.get(node.id) || false;
      
      if (visited.has(node.id)) {
          return currentPathSuccess;
      }

      visited.add(node.id);
      recursionStack.add(node.id);
      path.push(node);
      
      let success = node.data.endpointType === 'end';

      const outgoingEdges = edges.filter(edge => edge.source === node.id);
      if (!success && outgoingEdges.length == 0) {
          errors.push({ node, reason: 'Dead end' });
      }

      outgoingEdges.forEach(edge => {
          const targetNode = nodes.find(n => n.id === edge.target);
          if (edge.source === edge.target && edge.data.choiceType !== 'hint') {
              errors.push({ edge, reason: 'Needs to be a hint' });
          } else if (targetNode) {
              if (recursionStack.has(targetNode.id)) {  // Cycle detection
                  let cycle = path.slice(path.indexOf(targetNode)).map(n => n.id);
                  let validCycle = hasPathToEnd(targetNode.id);
                  if (!validCycle) {
                      cycle.forEach(nodeId => pathSuccess.set(nodeId, false));
                  }
                  success = validCycle || success;
              } else {
                  success = dfs(targetNode, path) || success;
              }
          }
      });

      recursionStack.delete(node.id);
      path.pop();
      pathSuccess.set(node.id, success);  // Mark current node success status

      if (success) {
          // Propagate success to all nodes in the path
          path.forEach(p => pathSuccess.set(p.id, true));
      }

      return success;
  }

  startNodes.forEach(node => dfs(node));
  
  // Final check for unreachable and dead cycle nodes
  nodes.forEach(node => {
      if (!visited.has(node.id) && node.data.endpointType !== 'start') {
          errors.push({ node, reason: 'Unreachable node' });
      }
      if (!pathSuccess.get(node.id) && visited.has(node.id)) {
          errors.push({ node, reason: 'Part of a dead cycle' });
      }
  });
  
  return errors;
};
