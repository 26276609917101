import React, { useState, useContext, useEffect, useRef } from 'react';
import CanvasContext from '../../../context/CanvasContext';
import styles from './styles/CreateSidebar.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const CreateSidebar = () => {
    const {
        onDragStart,
        onDragEnd,
        groups,
        situations,
        selectedGroup,
        selectedSituation,
        getGroup,
        getSituation,
        scenario,
    } = useContext(CanvasContext);

    const [collapsedGroups, setCollapsedGroups] = useState({});
    const [groupedSituations, setGroupedSituations] = useState({});
    const navigate = useNavigate();

    // Recalculate groupedSituations whenever groups or situations change
    useEffect(() => {
        const newGroupedSituations = groups.reduce((acc, group) => {
            acc[group._id] = situations.filter(situation => 
                situation.situationGroup && situation.situationGroup._id === group._id
            );
            return acc;
        }, {});

        setGroupedSituations(newGroupedSituations);
    }, [groups, situations]);

    const handleDragStart = (event, nodeType) => {
        const dragElement = event.target.cloneNode(true);
        dragElement.style.width = '100px';
        dragElement.style.height = '50px';
        if (nodeType === 'choiceNode') {
            dragElement.style.width = '60px';
            dragElement.style.height = '60px';
            dragElement.style.borderLeft = '20px solid transparent';
            dragElement.style.borderRight = '20px solid transparent';
            dragElement.style.borderTop = '40px solid lightgreen';
            dragElement.textContent = '';
            const textNode = document.createElement('span');
            textNode.textContent = 'Choice';
            textNode.style.position = 'absolute';
            textNode.style.top = '50%';
            textNode.style.left = '50%';
            textNode.style.transform = 'translate(-50%, -50%)';
            textNode.style.width = '100%';
            textNode.style.textAlign = 'center';
            dragElement.appendChild(textNode);
        }
        document.body.appendChild(dragElement);
        event.dataTransfer.setDragImage(dragElement, 75, 40);
        setTimeout(() => {
            document.body.removeChild(dragElement);
        }, 0);
        onDragStart(event, nodeType);
    };



    return (
        <div className={styles.container}>
            <div className={styles.titleContainer}>
                <h1>{scenario?.title || 'Untitled Scenario'}</h1>
            </div>
    
            <button onClick={() => navigate(`/create/${scenario?._id}`)}>
                Settings
            </button>
    
            <div className={styles.nodeTypes}>
                <div
                    draggable
                    onDragStart={(event) => handleDragStart(event, 'ovalNode')}
                    onDragEnd={onDragEnd}
                    className={styles.situation}
                >
                    Situation
                </div>
            </div>
    
            {/* Render the list based on the groupedSituations */}
            <div className={styles.list}>
                {groups.map((group) => (
                    <div key={group._id}>
                        <div
                            className={`${styles.groupTitle} ${selectedGroup?._id === group._id ? styles.selectedGroup : ''}`}
                            style={{
                                color: group.color === '#ffffff' ? 'black' : group.color,
                            }}
                            onClick={() => getGroup(group._id)}
                        >
                            <span
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setCollapsedGroups(prev => ({
                                        ...prev,
                                        [group._id]: !prev[group._id],
                                    }));
                                }}
                            >
                                {collapsedGroups[group._id]
                                    ? <FontAwesomeIcon icon={faCaretRight} />
                                    : <FontAwesomeIcon icon={faCaretDown} />}
                            </span>
                            {group.title || 'Untitled Group'}
                            {collapsedGroups[group._id] && (
                                <span>&nbsp;({groupedSituations[group._id]?.length || 0})</span>
                            )}


                        </div>
    
                        {!collapsedGroups[group._id] && groupedSituations[group._id]?.map(situation => (
                            <div
                                key={situation._id}
                                className={`${styles.situationItem} ${styles.groupedSituationItem} ${selectedSituation?._id === situation._id ? styles.selectedSituation : ''}`}
                                onClick={() => getSituation(situation._id)}
                                style={{
                                    '--bullet-color': group.color === '#ffffff' ? '#000' : group.color
                                }}
                            >
                                {situation.title || 'Untitled Situation'}
                            </div>
                        ))}
                    </div>
                ))}
    
                {/* Render situations without a group */}
                {situations.filter(situation => !situation.situationGroup).map(situation => (
                    <div
                        key={situation._id}
                        className={`${styles.situationItem} ${selectedSituation?._id === situation._id ? styles.selectedSituation : ''}`}
                        onClick={() => getSituation(situation._id)}
                        style={{ '--bullet-color': '#000' }}
                    >
                        {situation.title || 'Untitled Situation'}
                    </div>
                ))}
            </div>
        </div>
    );
    
    
}

export default CreateSidebar;
