import React from 'react';
import { transformIframes } from '../../../utils/utils';
import styles from './styles/SituationBody.module.css';

const SituationBody = ({ situation }) => {
    
    const isVideo = (file) => {
        const videoExtensions = ['.mp4', '.webm', '.ogg'];
        return videoExtensions.some(ext => file.endsWith(ext));
    };

    return (
        <div className={styles.container}>
            {situation.audio && (
                <div className={styles.mediaContainer}>
                    {isVideo(situation.audio) ? (
                        <video key={situation.audio} controls autoPlay className={styles.media}>
                            <source src={situation.audio} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    ) : (
                        <audio key={situation.audio} controls autoPlay className={styles.media}>
                            <source src={situation.audio} type="audio/mpeg" />
                            Your browser does not support the audio element.
                        </audio>
                    )}
                </div>
            )}
            {situation.image && (
                <div className={styles.imageContainer}>
                    <img src={situation.image} alt={situation.title} className={styles.image} />
                </div>
            )}
            <div
                className={styles.text}
                dangerouslySetInnerHTML={{ __html: transformIframes(situation.text) }}
            />
        </div>
    );
};

export default SituationBody;
