import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import styles from './styles/MyClassroomsGrid.module.css'; // import styles
import Modal from './Modal';
import './styles/ClassroomStyles.css';
import useMobileCheck from '../useMobileCheck';

const ClassroomsGrid = ({ classrooms, joinedClassrooms, setClassrooms, setMyClassroomsTabOpen }) => {

  //This will be changed
  const classroomImages = [
    'https://images.unsplash.com/photo-1509062522246-3755977927d7?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8Y2xhc3Nyb29tfGVufDB8fDB8fHwy',
    'https://images.unsplash.com/photo-1580582932707-520aed937b7b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8Y2xhc3Nyb29tfGVufDB8fDB8fHwy',
    'https://images.unsplash.com/file-1695862032969-7a88a86ce4d5image?dpr=2&w=416&auto=format&fit=crop&q=60',
    'https://images.unsplash.com/photo-1604134967494-8a9ed3adea0d?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8Y2xhc3Nyb29tfGVufDB8fDB8fHwy',
    'https://images.unsplash.com/photo-1503676260728-1c00da094a0b?q=80&w=2922&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    'https://images.unsplash.com/photo-1544640808-32ca72ac7f37?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8Y2xhc3Nyb29tfGVufDB8fDB8fHwy',
    'https://images.unsplash.com/photo-1585637071663-799845ad5212?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8Y2xhc3Nyb29tfGVufDB8fDB8fHwy',
    'https://images.unsplash.com/photo-1588072432836-e10032774350?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8Y2xhc3Nyb29tfGVufDB8fDB8fHwy',
    'https://images.unsplash.com/photo-1588075592446-265fd1e6e76f?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8Y2xhc3Nyb29tfGVufDB8fDB8fHwy',
    'https://images.unsplash.com/photo-1581726707445-75cbe4efc586?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGNsYXNzcm9vbXxlbnwwfHwwfHx8Mg%3D%3D',
    'https://images.unsplash.com/photo-1617721926586-4eecce739745?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGNsYXNzcm9vbXxlbnwwfHwwfHx8Mg%3D%3D'
  ];
  const isMobile = useMobileCheck();
  const navigate = useNavigate();
  const [openedDropdown, setOpenedDropdown] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingClassroom, setEditingClassroom] = useState(null);
  const [editingClassroomName, setEditingClassroomName] = useState('');
  const [image, setImage] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (openedDropdown && !event.target.closest(`.${styles.optionsDropdown}`) && !event.target.closest(`.${styles.optionsButton}`)) {
        setOpenedDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [openedDropdown, styles.optionsDropdown, styles.optionsButton]);

  useEffect(() => {
    if (editingClassroom) {
      setEditingClassroomName(editingClassroom.name);
    }
  }, [editingClassroom, isEditModalOpen]);

  const handleImageUpload = (uploadedImage) => {
    setImage(uploadedImage);
  };

  const editClassroom = async () => {
    if (editingClassroomName.trim() === "") {
      setError("This field cannot be empty");
      return;
    }

    const formData = new FormData();
    formData.append('name', editingClassroomName);
    if (image) {
      formData.append('image', image);
    }

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL_LOCAL}/api/classrooms/${editingClassroom._id}`,
        formData
      );
      const updatedClassrooms = classrooms.map((classroom) => {
        if (classroom._id === editingClassroom._id) {
          return { ...classroom, name: editingClassroomName, image: image };
        }
        return classroom;
      });
      setClassrooms(updatedClassrooms);
      setIsEditModalOpen(false);
    } catch (error) {
      console.log("Error updating classroom:", error);
    }
  };

  const handleEditNameChange = (e) => {
    setEditingClassroomName(e.target.value);
  };

  return (
    <div>
      {isMobile && (
        <ul className={styles.classroomList}>
          {classrooms.map((classroom) => (
            <li key={classroom._id}>
              <Link to={`/my-classrooms/${classroom._id}/a`}>
                {classroom.name}
              </Link>
            </li>
          ))}
        </ul>
      )}
      {isEditModalOpen && (
        <Modal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)} onSubmit={editClassroom} operation='edit' name={editingClassroomName} onImageUpload={handleImageUpload}>
          <input
            type="text"
            id="classroomName"
            value={editingClassroomName}
            onChange={handleEditNameChange}
            className='modal-input'
          />
          {error && <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>}
        </Modal>
      )}
      <div className={styles.classroomGrid}>
        {/* <div style={{ width: "80%" }}> */}
        {classrooms.slice().reverse().map((classroom, index) => (
          <Link to={`/my-classrooms/${classroom._id}/a`} key={classroom._id} style={{ textDecoration: 'none', color: 'inherit', width: 'fit-content' }}>
            <div className={styles.classroomItem} key={classroom._id} onClick={() => setMyClassroomsTabOpen(false)}>
              <img
                className={styles.classroomImage}
                src={classroom?.image ? classroom?.image : classroomImages[index % classroomImages.length]}
                alt="Classroom Icon"
              />

              <div className={styles.classroomName}>{classroom.name}</div>
              <button className={`${styles.optionsButton}`} onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setOpenedDropdown(classroom._id === openedDropdown ? null : classroom._id);
              }} />
              {openedDropdown === classroom._id && (
                <div className={styles.optionsDropdown}>
                  <div className={styles.optionsDropdownItem} onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setIsEditModalOpen(true);
                    setEditingClassroom(classroom);
                    setOpenedDropdown(false);
                  }}>
                    Edit
                  </div>
                </div>
              )}
            </div>
          </Link>
        ))}
        {joinedClassrooms.slice().reverse().map((classroom, index) => (
          <Link to={`/my-classrooms/${classroom._id}/a`} key={classroom._id} style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className={styles.classroomItem} key={classroom._id} onClick={() => setMyClassroomsTabOpen(false)}>
              <img
                className={styles.classroomImage}
                src={classroom.image ? classroom.image : classroomImages[index % classroomImages.length]}
                alt="Classroom Icon"
              />

              <div className={styles.classroomName}>{classroom.name}</div>
              <div className={styles.classroomOwner}>Owner: {classroom.owner.username}</div>
              <button className={styles.optionsButton} onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setOpenedDropdown(classroom._id === openedDropdown ? null : classroom._id);
              }} />
              {openedDropdown === classroom._id && (
                <div className={styles.optionsDropdown}>
                  <div className={styles.optionsDropdownItem}>
                    Placeholder
                  </div>
                </div>
              )}
            </div>
          </Link>
        ))}
        {/* </div> */}
      </div>
    </div>
  );
};

export default ClassroomsGrid;
