import React, { useContext, useRef, useEffect, useState } from 'react';
import styles from './styles/NavbarNotifications.module.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AuthContext from '../../../context/AuthContext';
import { transformIframes } from '../../../utils/utils';

const NavbarNotifications = ({ notifications, setNotifications, setShowNotifications }) => {
    const navigate = useNavigate();
    const { userId } = useContext(AuthContext);
    const notificationsRef = useRef(null);
    const [visibleNotifications, setVisibleNotifications] = useState(5); // Start with 5 notifications

    // Close notifications when clicking outside
    const handleClickOutside = (event) => {
        if (notificationsRef.current && !notificationsRef.current.contains(event.target)) {
            setShowNotifications(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Mark notification as seen
    const markAsSeen = async (notificationId) => {
        try {
            await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/notifications/markNotificationAsSeen/${userId}/${notificationId}`);
        } catch (err) {
            console.error("Error marking notification as seen:", err);
        }
    };

    // Handle clicking on a notification
    const handleNotificationClick = async (notification) => {
        if (notification && notification._id && notification.notificationType !== "invite") {
            if (!notification.seen) {
                await markAsSeen(notification._id);
                setNotifications((prevNotifications) => prevNotifications.map((notif) =>
                    notif._id === notification._id ? { ...notif, seen: true } : notif
                ));
            }
            navigateNotification(notification);
        }
    };

    const navigateNotification = (notification) => {
        if (notification.notificationType === "announcement") {
            navigate(`/my-classrooms/${notification.relatedClassroomId}/a`);
        } else if (notification.notificationType === "feedback" || notification.notificationType === "feedbackMessage") {
            navigate(`/user/${notification.relatedUser.username}`, { state: { activeTab: "Feedback" } });
        } else if (notification.notificationType === "acceptedInvite") {
            navigate(`/my-classrooms/${notification.relatedClassroomId}/p`);
        }
        setShowNotifications(false);
    };

    // Load more notifications either by button click or when reaching the bottom
    const loadMoreNotifications = () => {
        setVisibleNotifications((prevVisible) => prevVisible + 5); // Load 5 more notifications
    };

    // Automatically load more notifications when reaching the bottom
    const handleScroll = () => {
        const element = notificationsRef.current;
        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            // User has reached the bottom, load more notifications automatically
            // loadMoreNotifications();
            /* Todo--Not loadMoreNotifications just yet, we later on can have to have to get more than 5 from the server...later on clicking "See More Notifications" will actually fetch more from the Axios route, from the server and update the local state. Then we can have auto-load more notifications on scroll!  */
        }
    };

    useEffect(() => {
        const currentRef = notificationsRef.current;
        if (currentRef) {
            currentRef.addEventListener('scroll', handleScroll);
            return () => {
                currentRef.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);

    // Prevent scrolling from propagating to the outer window
    const stopScrollPropagation = (event) => {
        const element = notificationsRef.current;

        if ((element.scrollTop === 0 && event.deltaY < 0) || (element.scrollTop + element.clientHeight >= element.scrollHeight && event.deltaY > 0)) {
            event.preventDefault(); // Stop scrolling at the top or bottom
        }
    };

    useEffect(() => {
        const currentRef = notificationsRef.current;
        if (currentRef) {
            currentRef.addEventListener('wheel', stopScrollPropagation, { passive: false });
            return () => {
                currentRef.removeEventListener('wheel', stopScrollPropagation);
            };
        }
    }, []);

    // Sort notifications by time, unseen first
    const sortedNotifications = [...notifications].sort((a, b) =>
        a.seen === b.seen ? new Date(b.time) - new Date(a.time) : a.seen ? 1 : -1
    );

    return (
        <div ref={notificationsRef} className={styles.dropdown}>
            <div className={styles.header}>
                <h2>Notifications</h2>
            </div>
            {sortedNotifications.slice(0, visibleNotifications).length > 0 ? (
                <>
                    {sortedNotifications.slice(0, visibleNotifications).map((notification, index) => (
                        <div
                            key={index}
                            className={`${styles.notificationItem} ${!notification.seen ? styles.unseen : ''}`}
                            onClick={() => handleNotificationClick(notification)}
                        >
                            <p dangerouslySetInnerHTML={{ __html: transformIframes(notification.message) }} />
                            {notification.notificationType === "invite" && (
                                <>
                                    <div className={styles.notificationActions}>
                                        <button
                                            className={styles.acceptButton}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                acceptInvite(notification.relatedClassroomId, notification.role, notification._id);
                                            }}
                                        >
                                            Accept Invite
                                        </button>
                                        <button
                                            className={styles.ignoreButton}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                ignoreInvite(notification._id);
                                            }}
                                        >
                                            Ignore
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    ))}

                    {/* Show Load More button if there are more notifications to load */}
                    {visibleNotifications < sortedNotifications.length && (
                        <div className={styles.loadMoreContainer}>
                            <button className={styles.loadMoreButton} onClick={loadMoreNotifications}>
                                See More Notifications
                            </button>
                        </div>
                    )}
                </>
            ) : (
                <div className={styles.noNotifications}>
                    You have no notifications
                </div>
            )}
        </div>
    );
};

export default NavbarNotifications;
