import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './styles/TopBar.module.css';
import { useScenario } from '../../../context/ScenarioContext';
import SetOrderModal from './nSetOrderModal';
import CanvasContext from '../../../context/CanvasContext';
import { checkValidity } from '../../../utils/utils';
import  { Tooltip } from 'react-tooltip';
import {
    FaList,
    FaSortNumericDown,
    FaUserPlus,
    FaCopy,
    FaCheckCircle,
    FaExclamationCircle,
    FaEye,
    FaPaperPlane,
    FaPlus,
    FaSearch,
  } from 'react-icons/fa';
import TableOfContents from './nTableOfContents';

const TopBar = () => {

    const { 
        scenario, situations, groups, nodes, edges, selectedNodeIds, updateNodes, createGroup, isLogged, onSelectGroup,
        copyNode, hasToc, setHasToc, updateSituation, graphErrors, setGraphErrors, showTocEditor, setShowTocEditor,
    } = useContext(CanvasContext);

    const navigate = useNavigate();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isSetOrderModalOpen, setIsSetOrderModalOpen] = useState(false);
    const { setCurrentSituationId } = useScenario();

    // Calculate the disabled state for reuse
    const isButtonDisabled = !selectedNodeIds || selectedNodeIds.length < 1;

    // Function to toggle dropdown visibility
    const toggleDropdown = () => {
        // Only toggle if the button is not disabled
        if (!isButtonDisabled) {
            setIsDropdownOpen(!isDropdownOpen);
        }
    };

    useEffect(() => {
        if (isButtonDisabled) {
            setIsDropdownOpen(false);
        }
    }, [isButtonDisabled, isDropdownOpen]);


    const handleCreateGroupClick = () => {
        if (!isButtonDisabled) {
            createGroup()
                .then(async (newGroup) => {
                    if (newGroup && newGroup._id) {
                        // Step 1: Update each situation to associate it with the new group
                        await Promise.all(
                            selectedNodeIds.map(async (nodeId) => {
                                await updateSituation({ situationGroup: newGroup._id }, nodeId);
                            })
                        );
    
                        // Step 2: Update the color of each selected node to the new group's color
                        const updatedNodes = nodes.map(node =>
                            selectedNodeIds.includes(node.id)
                                ? { ...node, data: { ...node.data, fillColor: newGroup.color } }
                                : node
                        );
                        await updateNodes(updatedNodes);
    
                        // Step 3: Select the newly created group and close the dropdown
                        onSelectGroup(newGroup._id);
                        setIsDropdownOpen(false);
                        
                        console.log("Group created, situations associated, and node colors updated.");
                    }
                })
                .catch(err => {
                    console.error("Error in handleCreateGroupClick: ", err);
                });
        }
    };
    
    
    

    const handleAddToGroupClick = async (group) => {
        if (!isButtonDisabled) {
            if (!group._id || selectedNodeIds.length === 0) {
                console.error("No group selected or no situations to add.");
                return;
            }
    
            try {
                await Promise.all(
                    selectedNodeIds.map(async (nodeId) => {
                        await updateSituation({ situationGroup: group._id }, nodeId);
                    })
                );
    
                const updatedNodes = nodes.map(node =>
                    selectedNodeIds.includes(node.id)
                        ? { ...node, data: { ...node.data, fillColor: group.color } }
                        : node
                );
                await updateNodes(updatedNodes);
    
                onSelectGroup(group._id);
                setIsDropdownOpen(false);
    
                console.log("Situations successfully added to the group and node colors updated");
            } catch (error) {
                console.error("Error adding situations to group: ", error);
            }
        }
    };
    
    
    


    /*
    Checks the graph for any errors before publishing.
    If any errors exist, set them and prevent publishing
    If there are no errors, proceed with publishing
    */
    const handlePublish = () => {
        const errors = checkValidity(nodes, edges);
    
        if (errors.length > 0) {
            setGraphErrors(errors);
        } else {
            const situationIds = nodes.map(node => node.id);
            const data = {
                situations: situationIds,
                isPublished: true,
                isEditing: false
            };
    
            const currentTime = Date.now();
    
            if (scenario.isPublished) {
                data.lastUpdated = currentTime;
            } else {
                data.publishedAt = currentTime;
                data.lastUpdated = currentTime;
            }
    
            axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/${scenario?._id}`, data)
                .then(response => {
                    navigate('/home');
                })
                .catch(error => {
                    console.error(`Error publishing: ${error}`);
                });
        }
    };
    
    

    const createTableOfContents = async () => {
        if (hasToc) {
        //   navigate(`/create/${scenario._id}/table-of-contents`, { state: { situations: situations }});
            setShowTocEditor(true);
        } else {
          try {
            const response = await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/${scenario._id}/create-toc`);
    
            if (response.status === 200) {
                setHasToc(true);
                setShowTocEditor(true);
            } else {
              console.error("Failed to update scenario.");
            }
          } catch (err) {
            console.error("An error occurred while updating the scenario:", err);
          }
        }
    };



    const handleClickPreview = () => {
        setCurrentSituationId(scenario._id, null);
        navigate(`/create/${scenario._id}/preview`);
    }

    const openSetOrderModal = () => {
        setIsSetOrderModalOpen(true);
    }

    const closeSetOrderModal = () => {
        setIsSetOrderModalOpen(false);
    }
    
    
    
    
    return (
        <>
            {showTocEditor && (
                <div className={styles.tocContainer}>
                    <TableOfContents />
                </div>
            )}
            <div className={styles.container}>
                <div
                    className={styles.item}
                    onClick={createTableOfContents}
                    data-tooltip-id="tooltip"
                    data-tooltip-content="Table of Contents"
                >
                    <FaList />
                </div>
                {isLogged && (
                    <div
                        className={styles.item}
                        onClick={openSetOrderModal}
                        data-tooltip-id="tooltip"
                        data-tooltip-content="Set Order"
                    >
                        <FaSortNumericDown />
                    </div>
                )}
                <div
                    className={styles.item}
                    onClick={toggleDropdown}
                    aria-disabled={isButtonDisabled}
                    data-tooltip-id="tooltip"
                    data-tooltip-content="Add to Group"
                >
                    <FaUserPlus />
                </div>
                <div
                    className={styles.item}
                    onClick={() => copyNode(selectedNodeIds[0])}
                    aria-disabled={isButtonDisabled}
                    data-tooltip-id="tooltip"
                    data-tooltip-content="Copy Node"
                >
                    <FaCopy />
                </div>
                <div
                    className={styles.item}
                    onClick={() => {
                        const errors = checkValidity(nodes, edges);
                        setGraphErrors(errors);
                    }}
                    data-tooltip-id="tooltip"
                    data-tooltip-content="Check Validity"
                >
                    {graphErrors === null ? (
                        <FaSearch />
                    ) : graphErrors.length > 0 ? (
                        <FaExclamationCircle style={{ color: 'red' }} />
                    ) : (
                        <FaCheckCircle style={{ color: 'green' }} />
                    )}
                </div>
                <div
                    className={styles.item}
                    onClick={handleClickPreview}
                    data-tooltip-id="tooltip"
                    data-tooltip-content="Preview"
                >
                    <FaEye />
                </div>
                <div
                    className={styles.item}
                    onClick={handlePublish}
                    data-tooltip-id="tooltip"
                    data-tooltip-content="Publish"
                >
                    <FaPaperPlane />
                </div>
                {isDropdownOpen && (
                    <div className={styles.dropdown}>
                        <div
                            onClick={handleCreateGroupClick}
                            className={`${styles.dropdownItem} ${styles.createGroupItem}`}
                            data-type="create"
                            data-tooltip-id="tooltip"
                            data-tooltip-content="Create New Group"
                        >
                            <FaPlus className={styles.plusIcon} />
                            <div className={styles.newGroupText}>New Group</div>
                        </div>
                        {groups.map((group, index) => (
                            <div
                                key={index}
                                className={styles.dropdownItem}
                                style={{
                                    borderColor: group.color, // Set the border color to the group color
                                }}
                                onClick={() => handleAddToGroupClick(group)}
                                data-type="group"
                                data-tooltip-id="tooltip"
                                data-tooltip-content={group.title || 'Untitled Group'}
                            >
                                {group.title || 'Untitled Group'}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            {isSetOrderModalOpen && (
                <SetOrderModal closeSetOrderModal={closeSetOrderModal} />
            )}
            <Tooltip id="tooltip" place="bottom" type="dark" effect="solid" style={{ zIndex: 9999 }} />
        </>
    );
}

export default TopBar;
