import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './styles/OtherScenarios.css';
import ScenarioCard from './ScenarioCard';

const OtherScenarios = () => {
  const [scenarios, setScenarios] = useState([]);

  // Get the scenarios of other users
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/all`)
      .then(response => setScenarios(response.data))
      .catch(error => console.error(`Error: ${error}`));
  }, []); // Empty array means this effect runs once on component mount

  return (
    <div className="customContainer">
      <h2 className="mb-4">Other User's Scenarios</h2>
      <div className="OtherScenariosRow">
        {[...scenarios].reverse().map(scenario => {
          if (!scenario.isPublished) {
            return null;  // returning null means nothing will be rendered for this scenario.
          }
          return (
            <ScenarioCard
              key={scenario._id}
              scenario={scenario}
            />
          )
        })}
      </div>
    </div>
  );
};

export default OtherScenarios;

