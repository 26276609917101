import React from 'react';
import axios from 'axios';

const ClassroomSettings = ({ isOwner, classroomId, navigate }) => {
  
  const deleteClassroom = async () => {
    try {
      if (classroomId) {
        const response = await axios.delete(`${process.env.REACT_APP_API_URL_LOCAL}/api/classrooms/${classroomId}`);
        if (response.status === 200) {
            navigate("/my-classrooms");
        }
      }
    } catch (error) {
      console.error("Failed to delete classroom: ", error);
    }
  };
  
  return (
    <div>
      <h1>Settings</h1>
      {isOwner && (
        <>
          <h2>Owner Settings</h2>
          <button onClick={deleteClassroom}>Delete Classroom</button>
        </>
      )}
      {!isOwner && (
        <h2>Member Settings</h2>
        // Other settings for non-owners can go here
      )}
    </div>
  );
};

export default ClassroomSettings;
