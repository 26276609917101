import React, { useState, useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import styles from './styles/ClassroomDescription.module.css';
import axios from 'axios';

const ClassroomDescription = ({ classroomId, isOwner, description, onDescriptionUpdate }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [newDescription, setDescription] = useState('');

    useEffect(() => {
        let isComponentMounted = true;
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/classrooms/${classroomId}`);
                if (isComponentMounted) {
                    setDescription(response.data.description);
                }
            } catch (error) {
                console.error('Error fetching classroom description:', error);
            }
        };

        fetchData();

        return () => {
            isComponentMounted = false;
        };
    }, [classroomId]);

    
    const handleSaveDescription = async () => {
        try {
            const response = await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/classrooms/${classroomId}`, {
                description: newDescription
            });
            if (response.data.description) {
                setDescription(response.data.description);
                if (onDescriptionUpdate) {
                    onDescriptionUpdate(response.data.description);
                }
                setIsEditing(false);
            } else {
                console.error('Description key not found in response:', response.data);
            }
        } catch (error) {
            console.error('Error saving classroom description:', error);
        }
    };


    return (
        <div className={styles.descriptionContainer}>
            {!isEditing ? (
                <>
                    {description ? (
                        <div className={styles.htmlContent} dangerouslySetInnerHTML={{ __html: description }} />
                    ) : (
                        <p className={styles.placeholderText}>No description available.</p>
                    )}
                    {isOwner && (
                        <button onClick={() => setIsEditing(true)}>Edit</button>
                    )}
                </>
            ) : (
                <div>
                    <CKEditor
                        editor={ClassicEditor}
                        data={newDescription ? newDescription : description}
                        onReady={(editor) => {
                        }}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setDescription(data);
                        }}
                        onBlur={() => {
                        }}
                        onFocus={() => {
                        }}
                    />
                    <button onClick={handleSaveDescription}>Save</button>
                </div>
            )}
        </div>
    );
};

export default ClassroomDescription;
