import React, { useContext } from 'react';
import { useStore } from 'react-flow-renderer';
import CanvasContext from '../../../context/CanvasContext';

const ChoiceEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  source,
  target,
  data,
  styleOverride = {},
}) => {

    const { selectedEdgeId, edges, getChoice } = useContext(CanvasContext);


    const isSelected = id === selectedEdgeId;

    const selectedEdgeStyle = {
        stroke: '#008080',
        strokeWidth: 2,
    };

    const selectedLabelStyle = {
        fill: '#008080',
    };
    

     
    const handleLabelClick = async (edgeId, event) => {
        event.stopPropagation();
        await getChoice(edgeId);
    };
    


    // Filter edges that have the same source and target
    const similarEdges = edges.filter(e => 
        (e.source === source && e.target === target) || 
        (e.source === target && e.target === source)
    );
    const edgeCount = similarEdges.length;

    // Calculate the deviation based on the number of similar edges
    const indexAmongSimilar = similarEdges.findIndex(e => e.id === id);
    const baseDeviation = 25; // Adjust this as needed

    // Calculate the vertical distance between the nodes
    const verticalDistance = Math.abs(sourceY - targetY);

    // Set the threshold as a percentage of the vertical distance
    const alignmentThresholdPercentage = 0.3; //30% of the vertical distance
    const alignmentThreshold = verticalDistance * alignmentThresholdPercentage;

    // Determine if the nodes are vertically aligned
    const isVerticallyAligned = Math.abs(sourceX - targetX) < alignmentThreshold;
    let deviation;
    let centerX, centerY;

    // Define multipliers for deviation
    const verticalDeviationMultiplier = 50; // Adjust for more/less vertical curve
    const horizontalDeviationMultiplier = 30; // Adjust for more/less horizontal spacing

    if (isVerticallyAligned) {
        // For vertical alignment, calculate horizontal deviation
        const horizontalDeviation = (indexAmongSimilar - (edgeCount - 1) / 2) * horizontalDeviationMultiplier;
        centerX = (sourceX + targetX) / 2 + horizontalDeviation;
        centerY = (sourceY + targetY) / 2;
    } else {
        // For non-vertical alignment, calculate vertical deviation
        deviation = edgeCount > 1 ? verticalDeviationMultiplier * indexAmongSimilar - (baseDeviation * (edgeCount - 1)) : 0;
        centerX = (sourceX + targetX) / 2;
        centerY = (sourceY + targetY) / 2 + deviation;
    }

    const isLoopEdge = source === target;
    let loopPath;

    // Define the loop path for self-referencing edges
    if (isLoopEdge) {
        const radiusX = (sourceX - targetX) * 0.6;
        const radiusY = 50;
        loopPath = `M ${sourceX - 5} ${sourceY} A ${radiusX} ${radiusY} 0 1 0 ${
            targetX + 2
        } ${targetY}`;
    }
    



    const edgePath = `M ${sourceX} ${sourceY} Q ${centerX} ${centerY} ${targetX} ${targetY}`;

    // Define default styles here
    const defaultStyle = {
        stroke: "#000000",
        strokeWidth: 1,
    };

    const edgeStyle = { 
        ...defaultStyle, 
        ...styleOverride,
        stroke: isSelected ? selectedEdgeStyle.stroke : defaultStyle.stroke,
        strokeWidth: isSelected ? selectedEdgeStyle.strokeWidth : defaultStyle.strokeWidth,
        cursor: 'pointer',
    };

    // Define the custom marker ID
    const markerId = `arrow-${id}`;


    // Calculate the midpoint for the label
    const midX = (sourceX + targetX) / 2;
    const midY = (sourceY + targetY) / 2;

    // Function to create labels for "similar edges" aka edges sharing the same source and target nodes
    const renderLabels = () => {
        const labelWidth = 100; 
        const labelHeight = 20; 
        const spacing = 0;  
    
        // Handle loop edge offset
        const labelOffsetY = isLoopEdge ? 75 + (edgeCount - 1) * 10 : 0;
    
        // Calculate container position for labels
        const containerX = midX - labelWidth / 2;
        const containerY = midY - (labelHeight * edgeCount + spacing * (edgeCount - 1)) / 2 - labelOffsetY;
    
        return (
            <g pointerEvents="all">
                {/* Background rectangle, not clickable */}
                <rect
                    x={containerX}
                    y={containerY}
                    width={labelWidth}
                    height={labelHeight * edgeCount + spacing * (edgeCount - 1)}
                    fill="#fff"
                    stroke="#000"
                    strokeWidth="1"
                    pointerEvents="none" // Disable pointer events on the background
                />

                {/* Individual labels with pointer cursor and click handlers */}
                {similarEdges.map((edge, index) => (
                    <rect
                        key={edge.id}
                        x={containerX}
                        y={containerY + index * (labelHeight + spacing)}
                        width={labelWidth}
                        height={labelHeight}
                        fill={edge.id === selectedEdgeId ? selectedLabelStyle.fill : "#eee"}
                        stroke="#000"
                        strokeWidth="1"
                        pointerEvents="all" // Ensure each individual rect captures events
                        onClick={(event) => handleLabelClick(edge.id, event)} // Attach onClick to each rect
                        style={{ cursor: 'pointer' }} // Add pointer cursor on hover
                    />
                ))}
                {similarEdges.map((edge, index) => (
                    <text
                        key={edge.id}
                        x={containerX + labelWidth / 2}
                        y={containerY + index * (labelHeight + spacing) + labelHeight / 2}
                        style={{ fontSize: 10, fill: '#000', textAnchor: 'middle', dominantBaseline: 'middle', cursor: 'pointer', userSelect: 'none' }}
                        className='noselect'
                        onClick={(event) => {
                            event.stopPropagation();  // Prevent the event from bubbling up
                            handleLabelClick(edge.id, event);  // Call the label click handler
                        }}
                    >
                        {edge.data.label || 'untitled'} {/* Default to 'Untitled' if label is empty */}
                    </text>
                ))}
            </g>
        );
    };
    
    
    


    return (
        <>
        {/* Define the SVG, custom marker, and path */}
        <svg style={{ overflow: 'visible', position: 'absolute', left: 0, top: 0 }}>
            <defs>
            <marker
                id={markerId}
                markerWidth="10"
                markerHeight="7"
                refX="9"
                refY="3.5"
                orient="auto-start-reverse"
                markerUnits="strokeWidth"
            >
                <path d="M0,0 L0,7 L9,3.5 z" fill={isSelected ? selectedEdgeStyle.stroke : "#000000"} />
            </marker>
            </defs>
            <path
                id={id}
                style={edgeStyle}
                className="react-flow__edge-path"
                d={isLoopEdge ? loopPath : edgePath}
                markerEnd={`url(#${markerId})`}
            />

            {/* Render the label */}
            {renderLabels()}
        </svg>
        </>
    );
};

export default ChoiceEdge;
