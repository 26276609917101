import React, { useEffect } from "react";
import styles from './styles/TabsBar.module.css';

const TabsBar = ({ activeTab, setActiveTab }) => {
    const tabs = ["Featured", "Most Viewed", "Education", "Entertainment", "Recently Published", "Recently Updated", "Recommended"];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [activeTab]);

    return (
        <div className={styles.tabsBar}>
            {tabs.map(tab => (
                <div
                    key={tab}
                    className={`${styles.tab} ${activeTab === tab ? styles.activeTab : ''}`}
                    onClick={() => setActiveTab(tab)}
                >
                    {tab}
                </div>
            ))}
        </div>
    );
};

export default TabsBar;
