import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import styles from './styles/SetOrderModal.module.css';
import CanvasContext from '../../../context/CanvasContext';

const SetOrderModal = ({ closeSetOrderModal }) => {

    const { situations, scenario, logOrder, setLogOrder } = useContext(CanvasContext);
    const [orderedSituations, setOrderedSituations] = useState([]);
    const [positions, setPositions] = useState({});
    const [errors, setErrors] = useState({});
    const [showInconsistencyPrompt, setShowInconsistencyPrompt] = useState(false);



    useEffect(() => {
        // Initialize orderedSituations based on logOrder or default order
        if (logOrder && logOrder.length > 0) {
            const ordered = logOrder.map(id => situations.find(situation => situation._id === id));
    
            // Check for undefined elements in the ordered array
            if (ordered.includes(undefined) || ordered.length !== situations.length) {
                setShowInconsistencyPrompt(true);
            } else {
                setOrderedSituations(ordered);
            }
        } else {
            setOrderedSituations(situations);
        }
    }, [logOrder, situations]);
    

    useEffect(() => {
        const initialPositions = orderedSituations.reduce((acc, situation, index) => {
            acc[situation._id] = index + 1; // 1-indexed
            return acc;
        }, {});
        setPositions(initialPositions);
    }, [orderedSituations]);

    const handlePositionChange = (id, value) => {
        if (!/^\d*$/.test(value)) return; // Allow only numbers
        const newPosition = parseInt(value, 10);
        setPositions({
            ...positions,
            [id]: newPosition,
        });
    };

    const handleSave = () => {
        // Validate positions
        const newOrder = Array.from(orderedSituations);
        newOrder.sort((a, b) => positions[a._id] - positions[b._id]);

        const positionValues = Object.values(positions);
        const uniquePositions = new Set(positionValues);
        const hasDuplicates = uniquePositions.size !== positionValues.length;
        const hasGaps = Math.max(...positionValues) !== positionValues.length;
        const hasInvalid = positionValues.some(pos => isNaN(pos) || pos < 1 || pos > orderedSituations.length);

        const newErrors = {};
        orderedSituations.forEach(situation => {
            const pos = positions[situation._id];
            if (isNaN(pos) || pos < 1 || pos > orderedSituations.length || hasDuplicates || hasGaps || hasInvalid) {
                newErrors[situation._id] = true;
            }
        });

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        setErrors({});
        setOrderedSituations(newOrder);

        // Update the scenario's logOrder on the server
        const newLogOrder = newOrder.map(situation => situation._id);
        axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/${scenario?._id}`, { logOrder: newLogOrder })
            .then(response => {
                setLogOrder(newLogOrder); // Update the log order in the parent component
            })
            .catch(error => {
                console.error('Error updating log order:', error);
            });

        closeSetOrderModal();
    };

    const handleClearOrder = (clearOrder) => {
        if (clearOrder) {
            axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/${scenario?._id}`, { logOrder: [] })
                .then(response => {
                    setLogOrder([]); // Clear the log order in the parent component
                    setOrderedSituations(situations); // Reset to the default order
                    setShowInconsistencyPrompt(false);
                })
                .catch(error => {
                    console.error('Error clearing log order:', error);
                });
        } else {
            setShowInconsistencyPrompt(false); // Just close the prompt without making changes
        }
    };
    

    return (
        <div className={styles.modalBackdrop}>
            <div className={styles.modalContent}>
                {showInconsistencyPrompt ? (
                    <div className={styles.promptContainer}>
                        <p>Inconsistency detected in the order. Would you like to clear the order?</p>
                        <button onClick={() => handleClearOrder(true)} className={styles.yesButton}>Yes</button>
                        <button onClick={() => handleClearOrder(false)} className={styles.noButton}>No</button>
                    </div>
                ) : (
                    <>
                        <h2>Set Order For Viewing Logs</h2>
                        <div className={styles.situationsList}>
                            {orderedSituations.map((situation) => (
                                <div key={situation._id} className={styles.situationItem}>
                                    <span>{situation.title}</span>
                                    <input
                                        type="number"
                                        value={positions[situation._id] || ''}
                                        onChange={(e) => handlePositionChange(situation._id, e.target.value)}
                                        className={errors[situation._id] ? styles.positionInputError : styles.positionInput}
                                    />
                                </div>
                            ))}
                        </div>
                        <button onClick={handleSave} className={styles.saveButton}>Save</button>
                        <button onClick={closeSetOrderModal} className={styles.closeButton}>Close</button>
                    </>
                )}
            </div>
        </div>
    );
    
};

export default SetOrderModal;