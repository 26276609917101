import React, { useState, useContext, useEffect } from 'react';
import styles from './styles/Navbar.module.css';
import NavbarMenu from './NavbarMenu';
import AuthContext from '../../../context/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import NavbarNotifications from './NavbarNotifications';
import { io } from 'socket.io-client';
import NavbarSearch from "./NavbarSearch"
import AuthModal from '../../auth/AuthModal';
import axios from 'axios';
import { FaBars, FaBell, FaPlus, FaSearch } from 'react-icons/fa'; // Import the search icon

const socket = io(`${process.env.REACT_APP_API_URL_LOCAL}`);

const Navbar = () => {
    const { userId, loggedIn, user, loading } = useContext(AuthContext);
    const [showMenu, setShowMenu] = useState(false);
    const [showNotifications, setShowNotifications] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [authModalState, setAuthModalState] = useState('login');
    const [acceptInvite, setAcceptInvite] = useState(false);
    const [isSearchExpanded, setIsSearchExpanded] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const navigate = useNavigate();
    const location = useLocation();

    const [notifications, setNotifications] = useState(user ? user.notifications : []);
    const [hasNewNotification, setHasNewNotification] = useState(false);

    useEffect(() => {
        if (location.state) {
            const { showModal, authModalState, acceptInvite } = location.state;
            setShowModal(showModal);
            setAuthModalState(authModalState);
            setAcceptInvite(acceptInvite);
        }
    }, [location.state]);

    useEffect(() => {
        if (user && user.notifications) {
            setNotifications(user.notifications);
            setHasNewNotification(user.notifications.some(notification => !notification.seen));
        }
    }, [user]);

    const handleNewNotification = (notification) => {
        setNotifications((prevNotifications) => {
            const updatedNotifications = [notification, ...prevNotifications];
            setHasNewNotification(updatedNotifications.some(notif => !notif.seen));
            return updatedNotifications;
        });
    };

    useEffect(() => {
        if (userId) {
            socket.emit('join', userId);
            socket.on('notification', handleNewNotification);

            return () => {
                socket.off('notification', handleNewNotification);
            };
        }
    }, [userId]);

    const handleClickCreate = async () => {
        try {
            const scenarioResponse = await axios.post(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/`);
            const scenarioId = scenarioResponse.data._id;
        
            await axios.post(`${process.env.REACT_APP_API_URL_LOCAL}/api/canvas/${scenarioId}`);
        
            navigate(`/create/${scenarioId}`);
        } catch (err) {
          console.error("Error in creating scenario or canvas: ", err);
        }
    };

    const handleClickNotifications = () => {
        setShowNotifications(!showNotifications);
        setShowMenu(false);
        setHasNewNotification(false);
    };

    const handleClickMenu = () => {
        setShowMenu(!showMenu);
        setShowNotifications(false);
    };

    const handleClickLogIn = () => {
        setShowModal(!showModal);
        setShowNotifications(false);
        setShowMenu(false);
        setAuthModalState('login');
    }

    const handleClickSignUp = () => {
        setShowModal(!showModal);
        setShowNotifications(false);
        setShowMenu(false);
        setAuthModalState('sign up');
    }

    const toggleSearch = () => {
        setIsSearchExpanded(!isSearchExpanded);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (loading) {
        return (
            <>
                { isMobile ? (
                    <div className={styles.navbarContainer}>
                        <img
                            className={styles.logo}
                            src={require('../../../images/logo_teal_background.png')}
                            alt="Home"
                            onClick={loggedIn === false ? () => navigate('/') : () => navigate('/home')}
                        />
                    </div>
                ) : (
                    <div className={styles.navbarContainer}>
                        <img
                            className={styles.logo}
                            src={require('../../../images/LOGO_aScenario.png')}
                            alt="Home"
                            onClick={loggedIn === false ? () => navigate('/') : () => navigate('/home')}
                        />
                    </div>
                )}
            </>
        );
    }

    return (
        <div className={styles.navbarContainer}>
            {isMobile ? (
                <>
                    <div className={styles.logoContainer}>
                        <img
                            className={styles.logo}
                            src={require('../../../images/logo_teal_background.png')}
                            alt="Home"
                            onClick={loggedIn === false ? () => navigate('/') : () => navigate('/home')}
                        />
                    </div>
                    {isSearchExpanded ? (
                        <div className={styles.expandedSearchContainer}>
                            <NavbarSearch isMobile={isMobile} setIsSearchExpanded={setIsSearchExpanded} />
                        </div>
                    ) : null }
                </>
            ) : (
                <>
                    <div className={styles.logoContainer}>
                        <img
                            className={styles.logo}
                            src={require('../../../images/LOGO_aScenario.png')}
                            alt="Home"
                            onClick={loggedIn === false ? () => navigate('/') : () => navigate('/home')}
                        />
                    </div>
                    <div className={styles.navbarSearchContainer}>
                        <NavbarSearch />
                    </div>
                </>
            )}
    
            <div className={styles.navbarItemContainer}>
                {(loggedIn && !acceptInvite) ? (
                    <>
                        {isMobile && (
                            <div className={styles.navbarItem} onClick={toggleSearch}>
                                <FaSearch className={styles.icon} />
                            </div>
                        )}
                        <div className={styles.navbarItem} onClick={handleClickCreate}>
                            <FaPlus className={styles.icon}/>
                        </div>
                        <div className={styles.navbarItem} onClick={handleClickNotifications}>
                            <FaBell className={styles.icon}/>
                            {hasNewNotification && <span className={styles.notificationBadge}>!</span>}
                        </div>
                        <div className={styles.navbarItem} onClick={handleClickMenu}>
                            <FaBars className={styles.icon}/>
                        </div>
                    </>
                ) : (
                    !loading && (
                        <>
                            {isMobile && (
                                <div className={styles.navbarItem} onClick={toggleSearch}>
                                    <FaSearch className={styles.icon} />
                                </div>
                            )}
                            <button className={styles.login} onClick={handleClickLogIn}>
                                Log In
                            </button>
                            <button className={styles.signup} onClick={handleClickSignUp}>
                                Sign Up
                            </button>
                        </>
                    )
                )}
            </div>
    
            {showMenu && (
                <NavbarMenu
                    setShowMenu={setShowMenu}
                    user={user}
                />
            )}
            {showNotifications && (
                <NavbarNotifications
                    notifications={notifications}
                    setNotifications={setNotifications}
                    setShowNotifications={setShowNotifications}
                />
            )}
            {showModal && (
                <AuthModal 
                    showModal={showModal} setShowModal={setShowModal} 
                    authModalState={authModalState} setAuthModalState={setAuthModalState}
                    acceptInvite={acceptInvite} setAcceptInvite={setAcceptInvite}
                />
            )}
        </div>
    );
    
    
};

export default Navbar;