import React, { useEffect, useState } from 'react';
import styles from './styles/ScenarioCarousels.module.css';
import axios from 'axios';
import ScenarioCarousel from './ScenarioCarousel';
import TabsBar from './TabsBar';

const ScenarioCarousels = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [scenarios, setScenarios] = useState([]);
  const [activeTab, setActiveTab] = useState('Featured');

  useEffect(() => {
    const fetchScenarios = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/all`);
        setScenarios(response.data);
      } catch (error) {
        console.error(`Error: ${error}`);
      }
    }
    fetchScenarios();
    setIsLoading(false);
  }, []);

  const featuredTitles = [
    'test scenario please ignore',
    'optimized steps to acing sat reading & writing',
    'decoding the black-scholes model',
    'diagnostic test - ap physics c: mechanics',
    'middle school physics - about',
    'word problem challenge l3-l4',
    'rate: guide to compare',
    'english sentences to math equations',
    'author\'s perspective',
    'author\'s tone',
    'figurative language',
    'allusion',
    'text structures',
    'grammar: subject-verb agreement (full copy)',
    'grammar: continuous tenses',
  ];

  // Featured scenarios filter and sort (case-insensitive)
  const filteredFeaturedScenarios = scenarios
  .filter(scenario => {
      if (!scenario.isPublished) {
          return false;
      }
      return featuredTitles.some(title => 
          title.toLowerCase() === scenario.title.toLowerCase()
      );
  })
  .sort((a, b) => {
      // Get the index of scenario titles in the featuredTitles array
      const indexA = featuredTitles.findIndex(title => title.toLowerCase() === a.title.toLowerCase());
      const indexB = featuredTitles.findIndex(title => title.toLowerCase() === b.title.toLowerCase());

      // Sort by the order of featuredTitles
      return indexA - indexB;
  });


  const filteredEducationScenarios = scenarios.filter(scenario => {
    if (!scenario.isPublished) {
      return false;
    }
    const lowerCaseTags = scenario.tags.map(tag => tag.toLowerCase());
    if (!lowerCaseTags.includes("education")) {
      return false;
    }
    return true;
  });

  const filteredEntertainmentScenarios = scenarios.filter(scenario => {
    if (!scenario.isPublished) {
      return false;
    }

    const lowerCaseTags = scenario.tags.map(tag => tag.toLowerCase());

    if (!lowerCaseTags.includes("entertainment")) {
      return false;
    }

    return true;
  });

  let recentlyCreatedScenarios = [...scenarios]
    .filter(scenario => scenario.isPublished)
    .sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt))
    .slice(0, 20);

  let recentlyUpdatedScenarios = [...scenarios]
    .filter(scenario => scenario.isPublished)
    .sort((a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated))
    .slice(0, 20);

  let popularScenarios = [...scenarios]
    .filter(scenario => scenario.isPublished)
    .sort((a, b) => b.views - a.views)
    .slice(0, 20); // Adjust the number to get the desired number of popular scenarios

  return (
    <div className={`${styles.container}`}>
      {!isLoading && (
        <div>
          <TabsBar activeTab={activeTab} setActiveTab={setActiveTab} />

          {activeTab === "Education" && (
            <ScenarioCarousel
              scenariosList={filteredEducationScenarios}
            />
          )}
          {activeTab === "Entertainment" && (
            <ScenarioCarousel
              scenariosList={filteredEntertainmentScenarios}
            />
          )}
          {activeTab === "Most Viewed" && (
            <ScenarioCarousel
              scenariosList={popularScenarios}
            />
          )}
          {activeTab === "Recently Published" && (
            <ScenarioCarousel
              scenariosList={recentlyCreatedScenarios}
            />
          )}
          {activeTab === "Recently Updated" && (
            <ScenarioCarousel
              scenariosList={recentlyUpdatedScenarios}
            />
          )}
          {activeTab === "Featured" && (
            <ScenarioCarousel
              scenariosList={filteredFeaturedScenarios}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ScenarioCarousels;
