import React, { useState } from 'react';
import styles from './styles/UserSubmittedBody.module.css';
import { useScenario } from '../../../context/ScenarioContext';

const UserSubmittedBody = ({ situation, setCurrentSituationId, isScenarioLogged, preview }) => {
    const [activeTab, setActiveTab] = useState('text');
    const [userInput, setUserInput] = useState('');
    const { addDecisionToLog } = useScenario();

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const handleSkipClick = async () => {
        if (isScenarioLogged && !preview) {
            await addDecisionToLog({ 
                type: 'input', 
                userInput: '[SKIP]'
            });
        }
        setCurrentSituationId(situation.choices[0].nextSituation._id);
        setUserInput('');
    };

    const handleSubmit = async () => {
        if (isScenarioLogged && !preview) {
            await addDecisionToLog({ 
                type: 'input', 
                userInput 
            });
        }
        setCurrentSituationId(situation.choices[0].nextSituation._id);
        setUserInput('');
    };

    return (
        <div className={styles.container}>
            <div className={styles.tabs}>
                <div
                    className={`${styles.tab} ${activeTab === 'text' ? styles.active : ''}`}
                    onClick={() => handleTabClick('text')}
                >
                    Enter Text
                </div>
                <div
                    className={`${styles.tab} ${activeTab === 'audio' ? styles.active : ''}`}
                    onClick={() => handleTabClick('audio')}
                >
                    Record Audio
                </div>
            </div>
            <div className={styles.tabContent}>
                {activeTab === 'text' ? (
                    <textarea 
                        className={styles.textarea} 
                        placeholder="Enter your text here..."
                        value={userInput}
                        onChange={(e) => setUserInput(e.target.value)}
                    ></textarea>
                ) : (
                    <div className={styles.recordAudio}>RECORD AUDIO (to be added)</div>
                )}
            </div>
            
            <div className={styles.buttonContainer}>
                <button className={styles.button} onClick={handleSubmit}>Submit</button>
                <button className={styles.button} onClick={handleSkipClick}>Skip</button>
            </div>
        </div>
    );
};

export default UserSubmittedBody;
